import ERRORS, { MISSING_REQUIRED_PARAMS, FAILED_IMAGE_UPLOAD, FAILED_FILE_UPLOAD } from "./../../resources/errors";
import { nFetchPostWithBearer } from "./../../lib/network";
import { validateTest, uploadFile, uploadPdfFile } from "./../../lib/helper";
import lodash from "lodash";

const URL = 'test/';

// export const OBJECT_LIST = "OBJECT_LIST";
// export const OBJECT_LIST_ERROR = "OBJECT_LIST_ERROR";
export const TEST_CREATE = "TEST_CREATE";
export const TEST_CREATE_ERROR = "TEST_CREATE_ERROR";
export const TEST_SAVE_START_DATA = "TEST_SAVE_START_DATA";
export const TEST_SAVE_START_DATA_ERROR = "TEST_SAVE_START_DATA_ERROR";
export const TEST_SAVE = "TEST_SAVE";
export const TEST_SAVE_ERROR = "TEST_SAVE_ERROR";
export const TEST_SET_ERROR = "TEST_SET_ERROR";
export const TEST_GET_ERROR = "TEST_GET_ERROR";
export const TEST_GET = "TEST_GET";
export const TEST_REMOVE_ERROR = "TEST_REMOVE_ERROR";
export const TEST_REMOVE = "TEST_REMOVE";
export const TEST_OK = "TEST_OK";
export const SET_QUIZ = "SET_QUIZ";
export const TEST_SAVE_HELPER = "TEST_SAVE_HELPER";

export function saveHelper(name:string,file:name, isFileExist) {
    return async (dispatch,getState) => {
        if(!name || (!isFileExist && !file)){
            return dispatch({
                type: TEST_SET_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        let avatarSrc = null;
        if(file) avatarSrc = await uploadFile(file);
        if(!file || avatarSrc){
            const { current } = getState().test;
            return nFetchPostWithBearer(URL+"save/helper", {
                id:current,
                name,
                avatarSrc
            }).then((res) => {
                if (res.result) {                    
                    return dispatch({
                        type: TEST_SAVE_HELPER,
                        avatarSrc
                    });
                }
                else
                    return dispatch({
                        type: TEST_SET_ERROR,                            
                        error: ERRORS[res.error]
                    });
                 }).catch((err) => { });
        }
        else return dispatch({
            type: TEST_SET_ERROR,                            
            error: FAILED_FILE_UPLOAD
        });
       
    }
}

export function saveSteps(start:number,data:array, steps) {
    return async (dispatch,getState) => {
        console.log(steps,start, data);
        if(!steps || !start || start==-1 || !validateTest(data,start)){
            return dispatch({
                type: TEST_SET_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }   
        for(const i in data){
            data[i].item_id = data[i].id;
            if(data[i]._id) data[i].id = data[i]._id;
        }
        if(data){
            data = data.filter((item)=>item);
            const { current } = getState().test;
            return nFetchPostWithBearer(URL+"save/steps", {
                id:current,
                data,
                start,
                steps
            }).then((res) => {
                if (res.result) {                    
                    return dispatch({
                        type: TEST_SAVE
                    });
                }
                else
                    return dispatch({
    
                        type: TEST_SET_ERROR,                            
                        error: ERRORS[res.error]
                    });
                 }).catch((err) => { });
        }
        else return dispatch({
            type: TEST_SET_ERROR,                            
            error: FAILED_FILE_UPLOAD
        });
       
    }
}
export function create(title:string) {
    return async (dispatch) => {
        if(!title){
            return dispatch({
                type: TEST_CREATE_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        return nFetchPostWithBearer(URL+"create", {
            title
        }).then((res) => {
            if (res.result) {                    
                return dispatch({
                    type: TEST_CREATE
                });
            }
            else
                return dispatch({
                    type: TEST_CREATE_ERROR,                            
                    error: ERRORS[res.error]
                });
             }).catch((err) => { });
    }
}
export function getCurrent(){
    return async (dispatch)=>{
        const id = await localStorage.getItem("current_quiz");
        return dispatch({
            type: SET_QUIZ,
            payload: id
        })
    }
}
export function get(){
    return async (dispatch) => {
        
        return nFetchPostWithBearer(URL+"list",{}).then( async (res) => {
            if (res.result) { 
                const tests = res.payload.tests;
                for(const i in tests){
                    const data = tests[i].data;
                    for(const j in data){
                        data[j]._id = data[j].id;
                        data[j].id = data[j].item_id;
                    }
                    tests[i].data = data;
                }    
                return dispatch({
                    type: TEST_GET,
                    payload: tests
                });
            }
            else
                return dispatch({
                    type: TEST_GET_ERROR,                            
                    error: ERRORS[res.error]
                });
             }).catch((err) => { console.log(err) });
    }
}
export function saveStartInfo(title,name, start_title, start_subtitle, img, button_text, is_privicy_enabled, privicy ){
    return async (dispatch, getState)=>{
        const { current, list } = getState().test;
        let quiz = lodash.pickBy(list,(q)=>q.id==current);
        quiz = quiz[Object.keys(quiz)[0]];
        if(!title || !name || !start_title || !start_subtitle || !(quiz.img_src || img) || !button_text || !(is_privicy_enabled && privicy || !is_privicy_enabled)){
            return dispatch({
                type: TEST_CREATE_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        let img_src = null;
        if(img){
            img_src = await uploadFile(img);
        }
        if(img && img_src || !img){
            return nFetchPostWithBearer(URL+"save/start",{
                title,
                name, 
                id:current,
                start:{
                    title:start_title, 
                    subtitle:start_subtitle
                }, 
                img_src, 
                button_text,
                is_privicy_enabled,
                privicy
            }).then((res) => {
                if (res.result) {                    
                    return dispatch({
                        type: TEST_SAVE_START_DATA
                    });
                }
                else
                    return dispatch({
                        type: TEST_SAVE_START_DATA_ERROR,                            
                        error: ERRORS[res.error]
                    });
                }).catch((err) => { });
        }
        else{
            return dispatch({
                type: TEST_SAVE_START_DATA_ERROR,                            
                error: FAILED_IMAGE_UPLOAD
            });
        }
    }
}
export function saveCatalog(file){
    return async (dispatch, getState)=>{
        if(!file){
            return dispatch({
                type: TEST_SAVE_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        file = await uploadFile(file);
        console.log('file', file);
        if(file){
            const { current } = getState().test;
            return nFetchPostWithBearer(URL+"save/catalog",{
                id:current,
                name:file
            }).then((res) => {
                if (res.result) {                    
                    return dispatch({
                        type: TEST_SAVE
                    });
                }
                else
                    return dispatch({
                        type: TEST_SAVE_ERROR,                            
                        error: ERRORS[res.error]
                    });
                }).catch((err) => { });
        }
        else{
            return dispatch({
                type: TEST_SAVE_ERROR,                            
                error: FAILED_FILE_UPLOAD
            });
        }
    }
}
export function saveResultPage(text,button_text){
    return async (dispatch, getState)=>{
        if(!text || !button_text){
            return dispatch({
                type: TEST_SET_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        const { current } = getState().test;
        return nFetchPostWithBearer(URL+"save/result",{
            id:current,
            text,
            button_text              
            }).then((res) => {
                if (res.result) {                    
                    return dispatch({
                        type: TEST_SAVE
                    });
                }
                else
                    return dispatch({
                        type: TEST_SET_ERROR,                            
                        error: ERRORS[res.error]
                    });
                }).catch((err) => { });
    }
}
// export function get(id:number){
//     return async (dispatch) => {
//         if(!id){
//             return dispatch({
//                 type: OBJECT_GET_ERROR,
//                 error: MISSING_REQUIRED_PARAMS
//             });
//         }
//         return nFetchPostWithBearer(URL+"get", {
//            id
//         }).then((res) => {
//             console.log(res);
//             if (res.result) {                    
//                 return dispatch({
//                     type: OBJECT_GET,
//                     payload:res.payload.object
//                 });
//             }
//             else
//                 return dispatch({
//                     type: OBJECT_CREATE_ERROR,                            
//                     payload: ERRORS[res.error]
//                 });
//             }).catch((err) => { });
//     }
// }
export function remove(id:number){
    return async (dispatch) => {
        if(!id){
            return dispatch({
                type: TEST_REMOVE_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        return nFetchPostWithBearer(URL+"remove", {
           id
        }).then((res) => {
            console.log(res);
            if (res.result) {                    
                return dispatch({
                    type:TEST_REMOVE
                });
            }
            else
                return dispatch({
                    type: TEST_REMOVE_ERROR,                            
                    payload: ERRORS[res.error]
                });
            }).catch((err) => { });
    }
}

export function set(id:number){
    return async (dispatch)=>{
       // await localStorage.setItem("current_quiz",id);
        return dispatch({
            type: SET_QUIZ,
            payload: id
        })
    }
}
export function copy(id:number){
    return async (dispatch) => {
        if(!id){
            return dispatch({
                type: TEST_SET_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        return nFetchPostWithBearer(URL+"copy", {
           id
        }).then((res) => {
            console.log(res);
            if (res.result) {                    
                return dispatch({
                    type:TEST_OK
                });
            }
            else
                return dispatch({
                    type: TEST_SET_ERROR,                            
                    payload: ERRORS[res.error]
                });
            }).catch((err) => { });
    }
}