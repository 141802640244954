import ERRORS, { MISSING_REQUIRED_PARAMS, FAILED_IMAGE_UPLOAD } from "./../../resources/errors";
import { nFetchPostWithBearer } from "./../../lib/network";
import { validateObject, uploadFile } from "./../../lib/helper";

const URL = 'object/';

export const ALL_OBJECT_LIST = "ALL_OBJECT_LIST";
export const OBJECT_LIST = "OBJECT_LIST";
export const OBJECT_LIST_ERROR = "OBJECT_LIST_ERROR";
export const OBJECT_CREATE = "OBJECT_CREATE";
export const OBJECT_CREATE_ERROR = "OBJECT_CREATE_ERROR";
export const OBJECT_GET = "OBJECT_GET";
export const OBJECT_SAVE = "OBJECT_SAVE";
export const OBJECT_GET_ERROR = "OBJECT_GET_ERROR";
export const OBJECT_SAVE_ERROR = "OBJECT_SAVE_ERROR";
export const OBJECT_REMOVE = "OBJECT_REMOVE";
export const OBJECT_REMOVE_ERROR = "OBJECT_REMOVE_ERROR";
export const RESET_lIST = "RESET_lIST";

export function list(category_id:number) {
    return async (dispatch, getState) => {
        const { current } = getState().test;
        return nFetchPostWithBearer(URL+"listByCategory", {category_id, quiz_id:current})
            .then((res) => {
                if (res.result) {                    
                    return dispatch({
                        type: OBJECT_LIST,
                        payload: res.payload.objects
                    });
                }
                else
                    return dispatch({
                        type: OBJECT_LIST_ERROR,                            
                        payload: ERRORS[res.error]
                    });
            }).catch((err) => { });
    }
}
export function allList(){
    return async (dispatch, getState) => {
        const { current } = getState().test;
        return nFetchPostWithBearer(URL+"list", { quiz_id:current})
            .then((res) => {
                if (res.result) {                    
                    return dispatch({
                        type: ALL_OBJECT_LIST,
                        payload: res.payload.objects
                    });
                }
                else
                    return dispatch({
                        type: OBJECT_LIST_ERROR,                            
                        payload: ERRORS[res.error]
                    });
            }).catch((err) => { });
    }
}
export function save(params:array,templateParams:array,id:number) {
    return async (dispatch, getState) => {
        const object = validateObject(params,templateParams, true);
        if(!object || !id ){
            return dispatch({
                type: OBJECT_SAVE_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        console.log(typeof object.img);
        if(object.img){
            if(typeof object.img != "string"){
                const imgName = await uploadFile(object.img);
                object.img = imgName;
            } 
        }
        if(object.prev_img){
            if(typeof object.prev_img != "string"){
                const imgName = await uploadFile(object.prev_img);
                object.prev_img = imgName;
            }
        }
        const { current } = getState().test;
        return nFetchPostWithBearer(URL+"save", {
            id,
            object,
            quiz_id:current
        }).then((res) => {
            console.log(res);
            if (res.result) {                    
                return dispatch({
                    type: OBJECT_SAVE
                });
            }
            else
                return dispatch({
                    type: OBJECT_SAVE_ERROR,                            
                    error: ERRORS[res.error]
                });
            }).catch((err) => { });
    }
}
export function create(params:array,templateParams:array, category_id:number, noCheck = false) {
    return async (dispatch, getState) => {
        const object = validateObject(params,templateParams,noCheck);
        if(!object){
            return dispatch({
                type: OBJECT_CREATE_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        if(object.img){
            if(typeof object.img != "string"){
                const imgName = await uploadFile(object.img);
                object.img = imgName;
            } 
        }
        if(object.prev_img){
            if(typeof object.prev_img != "string"){
                const imgName = await uploadFile(object.prev_img);
                object.prev_img = imgName;
            }
        }
        if(noCheck || (object.prev_img && object.img)){
            const { current } = getState().test;
            return nFetchPostWithBearer(URL+"create", {
                object,
                category_id,
                quiz_id:current
            }).then((res) => {
                    console.log(res);
                    if (res.result) {                    
                        return dispatch({
                            type: OBJECT_CREATE
                        });
                    }
                    else
                        return dispatch({
                            type: OBJECT_CREATE_ERROR,                            
                            error: ERRORS[res.error]
                        });
                }).catch((err) => { });
        }
        else{
            return dispatch({
                type: OBJECT_CREATE_ERROR,                            
                error: FAILED_IMAGE_UPLOAD
            });
        }
    }
}
export function get(id:number){
    return async (dispatch) => {
        if(!id){
            return dispatch({
                type: OBJECT_GET_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        return nFetchPostWithBearer(URL+"get", {
           id
        }).then((res) => {
            console.log(res);
            if (res.result) {                    
                return dispatch({
                    type: OBJECT_GET,
                    payload:res.payload.object
                });
            }
            else
                return dispatch({
                    type: OBJECT_CREATE_ERROR,                            
                    payload: ERRORS[res.error]
                });
            }).catch((err) => { });
    }
}
export function remove(id:number){
    return async (dispatch) => {
        if(!id){
            return dispatch({
                type: OBJECT_REMOVE_ERROR,
                error: MISSING_REQUIRED_PARAMS
            });
        }
        return nFetchPostWithBearer(URL+"remove", {
           id
        }).then((res) => {
            console.log(res);
            if (res.result) {                    
                return dispatch({
                    type: OBJECT_REMOVE,
                    payload:res.payload.object
                });
            }
            else
                return dispatch({
                    type: OBJECT_REMOVE_ERROR,                            
                    payload: ERRORS[res.error]
                });
            }).catch((err) => { });
    }
}
export function resetList(){
    return {
        type: RESET_lIST
    }
}
export function updatePrice(prices= []){
    return async (dispatch, getState) => {
        const { current } = getState().test;
       for(const id in prices){
            await nFetchPostWithBearer(URL+"updatePrice", {
                id,
                value:prices[id],
                quiz_id:current
            });
       }
       return;
      
    }

}