import { createStore,  applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory'
import rootReducer from './reducers/';

export const history = createHistory()
const middleware = [
    thunk,
    routerMiddleware(history)
]
const enhancers = []
if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }
  
const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)
export const store = createStore(
    connectRouter(history)(rootReducer),
    composedEnhancers
);
export default function configureStore() {
    return store;
}