import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSteps, get } from "./../../../actions/test";
import Test from "./../../../components/test/index.js";
import { allList } from "./../../../actions/object";
import Loading from "./../../../components/layouts/loading";
import lodash from "lodash";

class TestEdit extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false
        }
        this.save = this.save.bind(this);
    }
    async componentWillMount(){
        if(!this.props.currentQuiz) this.props.history.push(`/project/list/`);
        else{
            this.loadTest();
        }
    }
    loadTest(){
        this.setState({loading:true});
        console.log(this.props.currentQuiz);
        let test = lodash.pickBy(this.props.list,(t)=>t.id == this.props.currentQuiz);
        console.log(test);
        this.test = test[Object.keys(test)[0]];
        this.setState({loading:false});
    }
    async save(start,steps,data){
        this.setState({loading:true});
        await this.props.save(start,data, steps);
        if(!this.props.error){
            await this.props.getTests();
            this.loadTest();
        }
        else this.setState({loading:false});
    }
    render(){
        return <Test 
            testList={this.props.list}
            test={this.test}
            save={this.save} 
            error={this.props.error}
            loading={this.state.loading}/>
    }
}


const mapDispatchToProps = dispatch => ({
    save:(start,data,steps)=>dispatch(saveSteps(start,data,steps)),
    getAllObjects:()=>dispatch(allList()),
    getTests:()=>dispatch(get())
   
})
const mapStateToProps = state => ({
    error:state.test.error,
    list:state.test.list,
    currentQuiz:state.test.current
})
export default connect(mapStateToProps, mapDispatchToProps)(TestEdit);