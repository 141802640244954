import React, {Component} from "react";
import Workbook from 'react-excel-workbook';

export default class ObjectList extends Component{
    constructor(props){
        super(props);
    }
    renderRow(order, i){
        return (
            <tr key={i}>
                <th scope="row">{order.id}</th>
                <td>{order.type}</td>
                <td>
                    {order.name}
                </td>
                <td>
                    {order.sum}
                </td>
                <td>
                    {order.mobile_phone}
                </td>
                <td>
                    {order.email}
                </td>
            </tr>
        )
    }
    render(){
        return (
            <div class="page-content">
            <section class="no-padding-top">
          <div class="container-fluid">
            <div class="row">
            <div class="col-lg-12">
                <div class="block">
                  <div class="title"><strong>Заказы {this.props.name}</strong></div>
                  <Workbook filename="orders.xlsx" element={<button className="btn btn-lg btn-primary">Скачать xlsx</button>}>
                    <Workbook.Sheet data={this.props.list} name="Sheet A">
                         <Workbook.Column label="Тип" value="type"/>
                        <Workbook.Column label="Имя" value="name"/>
                        <Workbook.Column label="Сумма" value="sum"/>
                        <Workbook.Column label="Мобильный телефон" value="mobile_phone"/>
                        <Workbook.Column label="Электронный адрес" value="email"/>
                    </Workbook.Sheet>
                 
                    </Workbook>
                  <div class="table-responsive"> 
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Тип</th>
                          <th>Имя</th>
                          <th>Сумма</th>
                          <th>Мобильный номер</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                            {this.props.list && this.props.list.map(this.renderRow)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </section>
        </div>
        )
    }
}