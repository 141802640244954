import React, { Component } from "react";
import Item from "./../test/item";
import { allList } from "./../../actions/test";
import lodash from "lodash";
import { connect } from "react-redux";
import Loading from "./../layouts/loading";

class Test extends Component{
    constructor(props){
        super(props);
        this.state = {
            title:"",
            start:-1,
            end:-1,
            steps: null,
            loading:false
        }
        this.list = [];
        this.enabledItems = [];
        this.add = this.add.bind(this);
        this.copy = this.copy.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleParamsChange = this.handleParamsChange.bind(this);
        this.remove = this.remove.bind(this);
        this.save = this.save.bind(this);
        this.copy = this.copy.bind(this);
        this.removeFromEnabledItems = this.removeFromEnabledItems.bind(this);
    }
    copy(id){
        const key = this.getUniqueKey();
        let data = this.list[id];

        this.list[key]={
            id:key,
            title:data.title,
            label:data.label,
            values:data.values,
            params:data.params,
            type:data.type,
            auto_next: data.auto_next,
            category:data.category,
            helper_text:data.helper_text,
            isCatalogShow: data.isCatalogShow,
            prev:[]
        }
        this.forceUpdate();
    }
    async componentWillMount(){
        if(this.props.test){
            this.loadData(this.props.test);
        }
    }
    componentWillReceiveProps(next_props){
        if(this.props.test !=next_props.test){
            console.log(next_props.test);
            this.loadData(next_props.test);
        }

    }
    loadData(test){
        this.list = []
        let test_data = test.data;
        for(const i in test_data){
            this.list[test_data[i].id]=test_data[i];
        }
        this.setState({loading:true})
        for(const i in this.list){
            if(this.list[i].type == 1){
                this.enabledItems.push({
                    id:this.list[i].id,
                    value:this.list[i].id+".0",
                    title:this.list[i].title,
                    enabled:true
                })
            }
            else if(this.list[i].type == 2){
                const values = this.list[i].values;
                for(const j in values){
                    let object = lodash.pickBy(this.props.all_objectList,(object)=>object.id == values[j]);
                    
                    object = object[Object.keys(object)[0]];
                        const title = object ? object.backend_title : "DELETED";
                        this.enabledItems.push({
                            id:this.list[i].id,
                            value:this.list[i].id+"."+values[j],
                            title:this.list[i].title+"."+title,
                            enabled:true
                        })
                   
                }
            }
        }
       
        this.setState({
            loading:false,
            title:test.title,
            start:test.start,
            steps: test.steps
           // end:test.end
        });
    }
    getUniqueKey(){
        let key = this.list.length;
        while(true){
            let isExist = false;
            for(const i in this.list){
                if(this.list[i].id==key) isExist = true;
            }
            if(isExist){
                key++;
            }
            else break;
        }
        return key;
    }
    add(){
        const key = this.getUniqueKey();
        this.list[key]={
            id:key,
            title:"",
            subTitle:"",
            label:"",
            values:[],
            params:{from:0,to:0},
            type:1,
            category:null,
            isCatalogShow:false,
            helper_text:"",
            auto_next: false,
            prev:[]
        }
        this.forceUpdate();
    }
    async save(){
        this.props.save(this.state.start,this.state.steps,this.list);
    }
    remove(id){
        if(this.list.length == 1) this.list = [];
        else this.list =  this.list.filter((item)=>item.id!=id)
        this.enabledItems = this.enabledItems.filter((item)=>{if(item && item.value.split(".")[0] != id) return item;})
        this.forceUpdate();
    }
    removeFromEnabledItems(value){
        this.enabledItems = this.enabledItems.filter((item)=>{if(item && item.value != value) return item;})
        this.forceUpdate();
    }
    handleParamsChange(id,item,value){
        if(item != "prev") this.list[id][item] = value;
        this.updateEnabledItems(id,item,value);
        this.forceUpdate();
    }
    handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState({[name]:value})
    }
    c
    updateEnabledItems(id,area_name,value){
      
        let item = lodash.pickBy(this.enabledItems,(item)=>item && item.id == id);

        // if item don't exist yet
        if(area_name != "prev" && !lodash.size(item)){

            if(area_name == "title" && value && this.list[id].type == 1){
                    this.enabledItems.push({
                        id,
                        value:id+"."+0,
                        title:value,
                        enabled:true
                    });
            }
            else if((area_name == "values" && this.list[id].title) || 
                    (area_name == "title" && this.list[id].type == 2)){
             
                    if(typeof this.list[id].values == "object"){

                        this.list[id].values.forEach((i)=>{
                            if(i){
                                const tmp = lodash.pickBy(this.enabledItems,(ob)=>ob && ob.value == id+"."+i);

                                if(!lodash.size(tmp)){
                                    let object = lodash.pickBy(this.props.all_objectList,(ob)=>ob.id == i);
                                    object =object[Object.keys(object)[0]];
                                    this.enabledItems.push({
                                        id: id,
                                        value:id+"."+i,
                                        title:(area_name == "values" ? this.list[id].title : value) +"."+object.backend_title,
                                        enabled:true
                                    });
                                }
                            }
                        });
                    }
            }
        }
        else if(area_name == "prev"){
            const prev = this.list[id].prev;
            const add = lodash.difference(value,prev);
            const rem = lodash.difference(prev,value);
            for(const i in this.enabledItems){
                //_.difference([2, 1], [2, 3]);
                if(add.indexOf(this.enabledItems[i].value) != -1){
                    this.enabledItems[i].enabled = false;
                }
                else if(rem.indexOf(this.enabledItems[i].value) !=-1){
                    this.enabledItems[i].enabled = true;
                }
                // if(this.enabledItems[i].value == prev){
                //     this.enabledItems[i].enabled = true;
                // }

                // if(this.enabledItems[i].value == value){
                //     this.enabledItems[i].enabled = false;
                // }
            }
            this.list[id].prev = value;
        }
        else{
         
            const item_keys = Object.keys(item);
            //item = item[item_key];
            // title was edited
            if(area_name == "title"){
                for(const key of item_keys){
                  
                    let tmp  = this.enabledItems[key].title.split(".");
                    tmp[0] = value;
                    this.enabledItems[key].title = tmp.length == 2 ?  tmp[0]+"."+tmp[1] : tmp[0]; 
                }

            }
            else if(area_name == "category"){
                this.enabledItems = this.enabledItems.filter((_item)=>{ if(_item.id != id) return _item});
                this.list[id].values = []
              
            }
            // type was edited 
            else if(area_name == "type"){
                for(const key of item_keys){
                // item's type set to category, delete from enabledList 
                    if(this.list[id].type == 2) delete this.enabledItems[key];
                    // item's type set to spinner, delete all objects from enabledList and add item without objects 
                    if(this.list[id].type == 1){
                        this.enabledItems = this.enabledItems.map((_item)=>{if(_item.id != id) return _item;});
                        this.enabledItems.push({
                            id,
                            title:this.list[id].title,
                            value:id+"."+0,
                            enabled:true
                        })

                    }
             }
            }
            // objects was edited
            else if(area_name == "values"){
                if(typeof this.list[id].values == "object"){
                    this.list[id].values.forEach((i)=>{
                        const tmp = lodash.pickBy(this.enabledItems,(ob)=>ob && ob.value == id+"."+i);
                        if(!lodash.size(tmp)){
                            let object = lodash.pickBy(this.props.all_objectList,(ob)=>ob.id == i);                       
                            object =object[Object.keys(object)[0]];
                            this.enabledItems.push({
                                id: id,
                                value:id+"."+i,
                                title: this.list[id].title +"."+object.backend_title,
                                enabled:true
                            });
                        }
                    });
                } 
           }
        }
       this.forceUpdate();
    }
    render(){
        if(this.props.loading || this.state.loading) return <Loading/>
        return (
            <div class="page-content">
                <section class="no-padding-top">
                    <div class="container-fluid">
                        <div class="col-lg-12">
                            <div class="block">
                                <div class="title"><strong>Тест</strong></div>
                                <div class="block-body">
                                    {this.props.error.length ? <p>{this.props.error}</p> : null}
                                    <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">Начало</label>
                                        <div class="col-sm-9">
                                            <select
                                                onChange={this.handleChange} 
                                                name="start" 
                                                value={this.state.start}
                                                class={this.props.error && this.state.start == -1? "form-control mb-3 mb-3 is-invalid" : "form-control mb-3 mb-3"}>
                                                <option value={-1}>Не выбрано</option>
                                                {this.list && this.list.map((item)=>{
                                                        if(!item) return null;
                                                        return <option value={item.id}>{item.title}</option>;
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="line"></div>
                                    <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Количество шагов</label>
                                        <div class="col-sm-9">
                                            <input
                                                onChange={this.handleChange} 
                                                type="text"
                                                name="steps"
                                                value={this.state.steps}
                                                class={this.props.error && !this.state.steps ? "form-control is-invalid" : "form-control"}/>
                                        </div>
                                    </div>
                                    {/* <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">Конец</label>
                                        <div class="col-sm-9">
                                            <select
                                                onChange={this.handleChange} 
                                                name="end"
                                                value={this.state.end}
                                                class={this.props.error && this.state.end == -1? "form-control mb-3 mb-3 is-invalid" : "form-control mb-3 mb-3"}>
                                                 <option value={-1}>Не выбрано</option>
                                                {this.list && this.list.map((item)=>{
                                                        if(!item) return null;
                                                        return <option value={item.id}>{item.title}</option>;
                                                })}
                                            </select>
                                        </div>
                                    </div> */}
                                    <div class="form-group row">
                                        <div class="col-sm-2">
                                            <button
                                                onClick={this.add} 
                                                type="button" 
                                                class="btn btn-primary">Добавить вопрос</button>
                                        </div>
                                        <div class="col-sm-2">
                                            <button
                                                onClick={this.save} 
                                                type="button" 
                                                class="btn btn-primary">Сохранить</button>
                                        </div>
                                    </div>
                                    <div class="line"></div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            {this.list && this.list.map((item,i)=>{
                               
                                return <Item 
                                    key={i} 
                                    id={i}
                                    start={this.state.start}
                                    end={this.state.end}
                                    data={item}
                                    error={this.props.error}
                                    copy={this.copy}
                                    date={Date.now()}
                                    remove={this.remove}
                                    removeEnabledObject={this.removeFromEnabledItems}
                                    enabledItems={this.enabledItems}
                                    handleChange={this.handleParamsChange}/>})} 
                        </div>
                       
                    </div>
                </section>
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => ({})
const mapStateToProps = state => ({
    categoryList:state.category.list,
    objectList:state.object.list,
    all_objectList:state.object.all_list
})
export default connect(mapStateToProps, mapDispatchToProps)(Test);