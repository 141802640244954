import React, { PureComponent } from "react";
import UserSettings from "./../../components/user/settings";

export default class Settings extends PureComponent{
    render(){
        return (
            <div class="page-content">
                <section class="no-padding-top">
                    <div class="container-fluid">
                        <UserSettings/>
                    </div>
                </section>
            </div>
        )
    }
}