import React, {Component} from "react";
import { connect } from "react-redux";
import { getByUser  } from "./../../../actions/order";
import Loading from "./../../../components/layouts/loading";
import ObjectList from "./../../../components/order/list";

class OrderByQuiz extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false
        }
    }
    async componentWillMount(){
        this.setState({loading:true});
        await this.props.getByUser();
        this.setState({loading:false});
    }

    render(){
        if(this.state.loading) return <Loading/>;
        return <ObjectList list={this.props.list}/>
    }
}
const mapDispatchToProps = dispatch => ({
    getByUser:()=>dispatch(getByUser()),
})
const mapStateToProps = state => ({
    list:state.order.list
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderByQuiz);