export const MISSING_REQUIRED_PARAMS = "Не заполнены все обязательные поля.";
export const INVALID_USER_CREDENTIALS = "Неверный логин или пароль.";
export const ACCESS_DENIED = "Необходима авторизация.";
export const FAILED_IMAGE_UPLOAD = "Неудалось загрузить изображение.";
export const FAILED_FILE_UPLOAD = "Неудалось загрузить файл.";
export const INVALID_EMAILS = "Невалидные электронные адреса.";
export const INCORRECT_OLD_PASSWORDS = "Пароли не совпадают.";
export const INVALID_OLD_PASSWORD = "Неверный текущий пароль.";
export const USER_NOT_FOUND = "Пользователь не найден.";
export const EMAIL_NOT_FOUND = "Электронный адрес не найден.";

export default {
    MISSING_REQUIRED_PARAMS,
    INVALID_USER_CREDENTIALS,
    INVALID_OLD_PASSWORD,
    USER_NOT_FOUND,
    EMAIL_NOT_FOUND
}