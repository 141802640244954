import { SERVER_URL } from "./../resources/config";
import { store } from "./../store";

export function nFetchPostWithBearer(path,params){
    let { token }  = store.getState().user;
    return nFetchPost(path,params,token);
}

export function nFetchUploadFileWithBearer(file){
    let { token }  = store.getState().user;
    return nFetchUploadFile(file,token);
}


export function nFetchPost(path,body,token = undefined){
    let request = {};
    request.method = "POST";
    request.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        // 'Authorization': `Bearer ${token}`
    };
    if(token) request.headers.Authorization = `Bearer ${token}`;
    request.body = JSON.stringify(body);
    return nResponseHandler(nFetch(path,request));
}
export function nFetchUploadFile(file,token = undefined){
    let request = {};
    request.method = "POST";
    // request.headers = {
    //     'Content-Type':'multipart/form-data'
    // };
   // if(token) request.headers.Authorization = `Bearer ${token}`;
    // console.log("file",file);
    // const formData = new FormData();
    // formData.append("document",file);
    request.body = file;
    return nResponseHandler(nFetch("object/uploadImage",request));
}
// export function nFetchGet(path,params,token = undefined){
//     let request = {};
//     request.method = "GET";
//     request.headers = {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json'
//     };
//     if(token) request.Authorization = `Bearer ${token}`;
//     console.log(request);
//     return nResponseHandler(nFetch(path+renderParams(params),request));
// }

function nFetch(path,request){
    return fetch(SERVER_URL+path,request);
}

function nResponseHandler(response){
    return response.then((res)=>{
            if(res.status === 201 || 
                res.status == 200 ||
                res.status == 401) return res.json();
            else{
                console.log(res);
                nError();
                throw new Error("Server unavailable.")
            }
        }).catch((err)=>{
            console.log(`Request error: ${err}`);
            nError();
            throw new Error("Network error.")
        });
}
function nError(){
    store.dispatch({
        type:'NETWORK_ERROR'
    });
}
function renderParams(params){
    let result = "?";
    for(let param in params){
        result+=`${param}=${params[param]}&&`;
    }
    return result;
}
