import React, {Component} from "react";
import { connect } from "react-redux";
import { getByQuiz  } from "./../../../actions/order";
import { get  } from "./../../../actions/test";
import ObjectList from "./../../../components/order/list";
import Loading from "./../../../components/layouts/loading";
import lodash from "lodash";

class OrderByQuiz extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false
        }
    }
    async componentWillMount(){
        this.setState({loading:true});
        await this.props.getByQuiz(this.props.match.params.quiz_id);
        await this.props.getTests();
        let test = lodash.pickBy(this.props.list_quiz,(t)=>t.id == this.props.match.params.quiz_id);
        this.test = test[Object.keys(test)[0]];
        this.setState({loading:false});
    }

    render(){
        if(this.state.loading) return <Loading/>;
        return <ObjectList name={this.test && this.test.title} list={this.props.list}/>
    }
}
const mapDispatchToProps = dispatch => ({
    getTests:()=>dispatch(get()),
    getByQuiz:(id)=>dispatch(getByQuiz(id)),

})
const mapStateToProps = state => ({
    list:state.order.list,
    list_quiz:state.test.list
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderByQuiz);