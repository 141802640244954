import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { create  } from "./../../../actions/test";
import Loading from "./../../../components/layouts/loading";


class QuizCreate extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            title: ""
        }
        this.create = this.create.bind(this);
    }
    async create(){
        this.setState({loading:true});
        console.log("In create",this.state.title);
        await this.props.create(this.state.title);
        if(!this.props.error) this.props.history.push(`/project/list/`);
        this.setState({loading:false});
    }
    render(){
        if(this.state.loading) return <Loading/>
        return <Fragment>
              <div class="page-content">
            <div class="col-lg-6">
                <div class="block">
                    <div class="title"><strong>Создать квиз</strong></div>
                        <div class="block-body">
                            {this.props.error ? <p> {this.props.error}</p> : null}
                            <div class="form-group row">
                               <label class="col-sm-3 form-control-label">Название</label>
                                <div class="col-sm-9">
                                    <input
                                        onChange={(event)=>this.setState({title:event.target.value})} 
                                        type="text"
                                        name="title"
                                        value={this.state.title}
                                        class={this.props.error && !this.state.title ? "form-control is-invalid" : "form-control"}/>
                                </div>
                            </div>
                            <div class="line"></div>
                            <button
                                onClick={()=>this.create()} 
                                type="button" 
                                class="btn btn-primary">Создать</button>
                        </div>  
                    </div> 
                </div>
                </div>
            </Fragment>
    }
}


const mapDispatchToProps = dispatch => ({
    create:(title)=>dispatch(create(title))
})
const mapStateToProps = state => ({
    error:state.test.error
})
export default connect(mapStateToProps, mapDispatchToProps)(QuizCreate);