import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactLoading from 'react-loading';
import lodash from "lodash";
import { list } from "./../../../actions/object";
import { SERVER_URL } from "./../../../resources/config";


class TestItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            type:1,
            category:0,
            selectedObject:-1,
            selectedObjects:[],
            selectedPrev: -1,
            loading:false,
            title:"",
            subTitle:"",
            label:"",
            values:[]
        }
        this.addObject = this.addObject.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderSelectedObject = this.renderSelectedObject.bind(this);
        this.removeSelectedObject = this.removeSelectedObject.bind(this);
        this.renderPrevItems = this.renderPrevItems.bind(this);
        this.addPrevElement = this.addPrevElement.bind(this);
        this.removePrevElement = this.removePrevElement.bind(this);
    }
    async componentWillMount(){
        if(this.props.data){
            if(Number(this.props.data.type) == 2){
                await this.loadObjects(this.props.data.category);
                await this.setState({selectedObjects:this.props.data.values})
                //if(!this.props.data.category) this.props.handleChange(this.props.id,"category",this.props.categoryList[0].id);
            }
            else await this.setState({selectedObjects:[]})
            if(this.props.data.prev) this.props.handleChange(this.props.id,"prev",this.props.data.prev);
        }
    }
    async handleChange(event) {
        const target = event.target;
        let value = target.type === 'file' ? target.files[0] :  target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if(name == "type"){
               this.setState({selectedObjects:[]})
            value = Number(value);
            this.props.handleChange(this.props.id,"category",this.props.categoryList[0].id);
            await this.loadObjects(this.props.categoryList[0].id);
        }
        else if(name == "category"){
            this.setState({selectedObjects:[]})
            this.props.handleChange(this.props.id,"category",value);
            await this.loadObjects(value);
        }
        else if(name == "prev"){
            this.setState({
                selectedPrev: value
            });
            return;
        }
        else if(name == "values"){
            // let values = this.state.selectedObjects;
            // const findIndex = this.state.selectedObjects.indexOf(value);
            // if(findIndex == -1) values.push(value.id);
            // else{
            //     delete values[findIndex];
            // }
            this.setState({selectedObject: value});
           // console.log(values);
            return;
           // value = objects;
        }
        else if(name == "from" || name == "to"){
            let params = this.props.data.params;
            params[name]=Number(value);
            name = "params";
            value = params;
        }
        else this.setState({[name]: value});
        this.props.handleChange(this.props.id,name,value);
      //  this.forceUpdate();
    }
    addObject(){
        let values = this.state.selectedObjects || [];
        if(values.indexOf(this.state.selectedObject) == -1){
            values.push(Number(this.state.selectedObject));
            this.setState({
                selectedObjects:values
            });
            this.props.handleChange(this.props.id,"values",values);
        }
    }
    addPrevElement(){
        let prev_values =  this.props.data.prev.slice();
        if(prev_values.indexOf(this.state.selectedPrev) == -1){
          prev_values.push(this.state.selectedPrev);
          this.setState({selectedPrev:null})
            this.props.handleChange(this.props.id,"prev",prev_values);
        }
    }
    removePrevElement(id){
        let prev_values = this.props.data.prev;
        prev_values = prev_values.filter((val)=>val!=id);
        this.props.handleChange(this.props.id,"prev",prev_values);
    }
    renderPrevItems(value){
        const item = this.props.enabledItems.find((item)=>item.value == value);
        if(!item) return null;
        return (<tr>
            <td>{item.title}</td>
            <td> <button
                    onClick={()=>this.removePrevElement(value)} 
                    type="button" 
                    class="btn btn-primary">Удалить</button></td>
            {/* <td><a href="#" onClick={()=>this.removePrevElement(value)}>Удалить</a></td> */}
        </tr>)
    }
    async loadObjects(id){
        this.setState({loading:true});
        this.objectList = [];
        for(const i in this.props.all_objectList){
            if(this.props.all_objectList[i].category_id == id) this.objectList.push(this.props.all_objectList[i])
        }


        // await this.props.getObjects(id);
        // let selected = -1;
        // if(this.props.objectList.length){
        //     selected = this.props.objectList[0].id
        // }
        // this.objectList = this.props.objectList;
        let selected = -1;
        if(this.objectList.length){
            selected = this.objectList[0].id
        }
        this.setState({loading:false, selectedObject:selected});
       
    }
    removeSelectedObject(id){
        let values = this.state.selectedObjects;
        values = values.filter((i)=>i!=id);
        this.setState({selectedObjects:values})
        this.props.removeEnabledObject(this.props.id+"."+id);

    }
    renderSelectedObject(id){        
        let object = lodash.pickBy(this.objectList,(object)=>object.id == id);
        object = this.objectList[Object.keys(object)[0]];
        if(!object) return null;
        return (<tr>
            <td>{object.backend_title}</td>
            <td> <button
                    onClick={()=>this.removeSelectedObject(id)} 
                    type="button" 
                    class="btn btn-primary">Удалить</button></td>
            {/* <td><a href="#" onClick={()=>this.removeSelectedObject(id)}>Удалить</a></td> */}
        </tr>)
    }
    render(){   
        return (
            <div class="col-lg-6">
                <div class="block">
                    {this.state.loading ?  
                        <div className="element-test-loading">
                            <ReactLoading  
                                type={"spinningBubbles"}
                                color={" #DB6574"} 
                                height={'5%'} 
                                width={'5%'} />
                        </div>
                    :
                    <Fragment>
                    <div class="title"><strong>Элемент</strong></div>
                        <div class="block-body">
                            <div class="form-group row">
                               <label class="col-sm-3 form-control-label">Вопрос</label>
                                <div class="col-sm-9">
                                    <input
                                        onChange={this.handleChange} 
                                        type="text"
                                        name="title"
                                        value={this.props.data.title}
                                        class={this.props.error && !this.props.data.title.length ? "form-control is-invalid" : "form-control"}/>
                                        <small class="form-text">Текст вопроса</small>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Тип</label>
                                <div class="col-sm-9">
                                    <select
                                        onChange={this.handleChange} 
                                        name="type"
                                        value={this.props.data.type}
                                        class="form-control mb-3 mb-3">
                                        <option value="1">Ползунок</option>
                                        <option value="2">Варианты ответа</option>
                                    </select>
                                </div>
                            </div>
                            {this.props.data.type == 2 && 
                                <Fragment>
                                <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Категория</label>
                                    <div class="col-sm-4">
                                        <select
                                            onChange={this.handleChange} 
                                            name="category"
                                            value={this.props.data.category}
                                            class="form-control mb-3 mb-3">
                                            {this.props.categoryList.map((category,i)=><option value={category.id}>{category.title}</option>)}
                                        </select>
                                    </div>
                                </div>
                                {(this.objectList && this.objectList.length) ?
                                <div class="form-group row"> 
                                        <Fragment>
                                            <label class="col-sm-3 form-control-label"></label>
                                            <div class="col-sm-4">
                                                <select 
                                                    onChange={this.handleChange} 
                                                    name="values" 
                                                    class="form-control mb-3 mb-3">
                                                    {this.objectList.map((object,i)=>{
                                                        
                                                        if(this.state.selectedObject == object.id) return <option selected value={object.id}>{object.backend_title}</option>;
                                                        else return <option value={object.id}>{object.backend_title}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </Fragment>
                                    <div class="col-sm-4">
                                        <button
                                            onClick={this.addObject} 
                                            type="button" 
                                            class="btn btn-primary">Добавить</button>
                                    </div>                
                                </div> : null}
                                {(Number(this.props.data.type) == 2 && this.state.selectedObjects.length) ?
                                 <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Выбранные ответы</label>
                                        <div class="col-sm-9">
                                            <table class="table table-striped table-hover"> 
                                                {this.state.selectedObjects.map(this.renderSelectedObject)}
                                            </table>
                                        </div> 
                                </div>: null} 
                            </Fragment>}
                            {this.props.data.type == 1 ?
                                <Fragment>
                                     <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">От</label>
                                        <div class="col-sm-9">
                                            <input
                                                onChange={this.handleChange} 
                                                type="number"
                                                name="from"
                                                value={this.props.data.params.from}
                                                class={"form-control"}/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">До</label>
                                        <div class="col-sm-9">
                                            <input
                                                onChange={this.handleChange} 
                                                type="number"
                                                name="to"
                                                value={this.props.data.params.to}
                                                class={"form-control"}/>
                                        </div>
                                    </div>
                                </Fragment>
                            : null }
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Метка</label>
                                <div class="col-sm-9">
                                    <input
                                        onChange={this.handleChange} 
                                        type="text"
                                        name="label"
                                        value={this.props.data.label}
                                        class={"form-control"}/>
                                    <small class="form-text"></small>
                                </div>
                            </div>
                            <div class="line"></div>
                            {this.props.id != this.props.start ? 
                            <Fragment>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Вызывается после</label>
                                <div class="col-sm-5">
                                    <select
                                        onChange={this.handleChange} 
                                        name="prev" 
                                        class={this.props.error && this.props.data.prev == 0 ? "form-control mb-3 mb-3 is-invalid" : "form-control mb-3 mb-3"}>
                                        <option value="0">Не выбрано</option>
                                       {this.props.enabledItems.map((item)=>{
                                            if(!item) return null;
                                            if(item.value.split(".")[0] != String(this.props.id)){
                                                if(item.value == this.state.selectedPrev && item.enabled) return <option selected value={item.value}>{item.title}</option>;
                                                else if(item.enabled) return <option value={item.value}>{item.title}</option>;
                                            }
                                       })}
                                    </select>
                                </div>
                                <div class="col-sm-4">
                                        <button
                                            onClick={this.addPrevElement} 
                                            type="button" 
                                            class="btn btn-primary">Добавить</button>
                                </div> 
                                </div>
                                {this.props.data.prev.length ?
                                    <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">Вопросы</label>
                                            <div class="col-sm-9">
                                                <table class="table table-striped table-hover"> 
                                                    {this.props.data.prev.map(this.renderPrevItems)} 
                                                </table>
                                            </div> 
                                    </div>: null} 
                                </Fragment>
                            : null}
                            <div class="line"></div>
                            {/* <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Сообщение помощника</label>
                                <div class="col-sm-9">
                                    <textarea
                                        onChange={this.handleChange} 
                                        type="text"
                                        name="helper_text"
                                        class={this.props.error && !this.props.data.helper_text ? "form-control is-invalid" : "form-control"}>{this.props.data.helper_text}</textarea>
                                    <small class="form-text"></small>
                                </div>
                            </div>
                            <div class="line"></div> */}
                            {/* <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Показывать католог</label>
                                <div class="col-sm-9">
                                    <label class="checkbox-inline">
                                    {this.props.data.isCatalogShow ? 
                                        <input 
                                            checked
                                            id="inlineCheckbox1" 
                                            type="checkbox"
                                            onChange={this.handleChange} 
                                            name="isCatalogShow" 
                                          /> :
                                          <input 
                                            id="inlineCheckbox1" 
                                            type="checkbox"
                                            onChange={this.handleChange} 
                                            name="isCatalogShow" 
                                          />
                                    }
                                    </label>
                                </div>
                            </div> */}
                            <div class="line"></div>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Автопереход</label>
                                <div class="col-sm-9">
                                    <label class="checkbox-inline">
                                    {this.props.data.auto_next ? 
                                        <input 
                                            checked
                                            id="inlineCheckbox1" 
                                            type="checkbox"
                                            onChange={this.handleChange} 
                                            name="auto_next" 
                                          /> :
                                          <input 
                                            id="inlineCheckbox1" 
                                            type="checkbox"
                                            onChange={this.handleChange} 
                                            name="auto_next" 
                                          />
                                    }
                                    </label>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="form-group row">
                                <div class="col-sm-3">
                                    <button
                                        onClick={()=>this.props.remove(this.props.id)} 
                                        type="button" 
                                        class="btn btn-primary">Удалить</button>
                                </div>
                                <div class="col-sm-3">
                                    <button
                                        onClick={()=>this.props.copy(this.props.id)} 
                                        type="button" 
                                        class="btn btn-primary">Копировать</button>
                                </div>
                            </div>
                        </div>  
                        </Fragment>}
                    </div> 
                </div>)
    }
}
const mapDispatchToProps = dispatch => ({
    getObjects:(categoryId)=>dispatch(list(categoryId))
})
const mapStateToProps = state => ({
    categoryList:state.category.list,
    objectList:state.object.list,
    all_objectList:state.object.all_list
})
export default connect(mapStateToProps, mapDispatchToProps)(TestItem);