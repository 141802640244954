import {
    CATEGORY_LIST,
    CATEGORY_LIST_ERROR
} from "../../actions/category";

export type State = {
    error: string,
    list: array
}
const initialState = {
    error: "",
    list:[]
}

export default (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case CATEGORY_LIST:
            return {
                ...state,
                list: action.payload
            }
        case CATEGORY_LIST_ERROR:
            return {
                ...state,
                error:action.error
            }
        default:
            return state
    }
}