import ERRORS, { MISSING_REQUIRED_PARAMS, INVALID_EMAILS } from "./../../resources/errors";
import { nFetchPostWithBearer } from "./../../lib/network";
import { validateEmail } from "./../../lib/helper";

const URL = 'config/';

export const CONFIG_GET = "CONFIG_GET";
export const CONFIG_GET_ERROR = "CONFIG_GET_ERROR";
export const CONFIG_UPDATE_ERROR = "CONFIG_UPDATE_ERROR";
export const CONFIG_UPDATE = "CONFIG_UPDATE";

export function get() {
    return async (dispatch,getState) => {
        let { current } = getState().test;
        // if(!current){
        //     current = await localStorage.getItem("current_quiz")
        // }
        return nFetchPostWithBearer(URL+"get", {id:current}).then((res) => {
            if (res.result) {
                let data =res.payload.config.data;  
                return dispatch({
                    type: CONFIG_GET,
                    data
                });
            }
            else
                return dispatch({
                    type:  CONFIG_GET_ERROR,                            
                    error: ERRORS[res.error]
                });
             }).catch((err) => { });
    }
}

export function update(data) {
    return async (dispatch,getState) => {
       if(!data.fence_length || !data.emails){
            return dispatch({
                type: CONFIG_UPDATE_ERROR,
                error: MISSING_REQUIRED_PARAMS
            })
       }
       let emails = data.emails.split(",");
       let isValid = false;
       if(typeof emails == "object"){
            for(const i in emails){
                isValid = validateEmail(emails[i].replace(" ",""));
            }
       }
       if(!isValid){
            return dispatch({
                type: CONFIG_UPDATE_ERROR,
                error: INVALID_EMAILS
            })
        }
       const { current } = getState().test;
        return nFetchPostWithBearer(URL+"update", {data, id:current}).then((res) => {
            if (res.result) {                    
                return dispatch({
                    type: CONFIG_UPDATE
                });
            }
            else
                return dispatch({
                    type:  CONFIG_UPDATE_ERROR,                            
                    error: ERRORS[res.error]
                });
             }).catch((err) => { });
    }
}
