import React, { Component } from "react"; 
import { connect } from "react-redux";
import { setPass } from "./../../../actions/user";
import Loading from "./../../layouts/loading";

class Settings extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event){
        const target = event.target;
        let value = target.value;
        this.setState({[target.name]:value})
     }
     async save(){
        this.setState({loading:true});
        await this.props.setPass(this.state.new_password_1, this.state.new_password_2,this.state.old_password);
        this.setState({loading:false});
    }
    render(){
        if(this.state.loading) return <Loading/>
        return (
            <div class="col-lg-12">
            <div class="block">
                 <div class="title"><strong>Настройки аккаунта</strong></div>
                <div class="block-body">
                    {this.props.error && this.props.error}
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Текущий пароль</label>
                        <div class="col-sm-9">
                            <input
                                onChange={this.handleChange} 
                                type="password"
                                name="old_password"
                                value={this.state.old_password}
                                class={this.props.error && !this.state.old_password ? "form-control is-invalid" : "form-control"}/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Новый пароль</label>
                        <div class="col-sm-9">
                            <input
                                onChange={this.handleChange} 
                                type="password"
                                name="new_password_1"
                                value={this.state.new_password_1}
                                class={this.props.error && !this.state.new_password_1 ? "form-control is-invalid" : "form-control"}/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Повторите пароль</label>
                        <div class="col-sm-9">
                            <input
                                onChange={this.handleChange} 
                                type="password"
                                name="new_password_2"
                                value={this.state.new_password_2}
                                class={this.props.error && !this.state.new_password_2 ? "form-control is-invalid" : "form-control"}/>
                        </div>
                    </div>
                    <button
                        onClick={()=>this.save()} 
                        type="button" 
                        class="btn btn-primary">Сохранить</button>
                </div>
            </div>
        </div>    
        )
    }
}
const mapDispatchToProps = dispatch => ({
  
    setPass:(new_pass_1,new_pass_2,old_pass)=>dispatch(setPass(new_pass_1,new_pass_2,old_pass))
})
const mapStateToProps = state => ({
    error:state.user.error
})
export default connect(mapStateToProps, mapDispatchToProps)(Settings);