import React, { Component } from "react";
import QuizSettings from "./../../../components/test/settings";
import UserSettings from "./../../../components/user/settings";
import Config from "./../../../components/config";
import Script from "./../../../components/script";
import { connect } from "react-redux"

 class Setting extends Component{
    constructor(props){
        super(props);
        this.state = {
            title: ""
        }
        this.setTitle = this.setTitle.bind(this);
    }
    componentWillMount(){
        if(!this.props.currentQuiz) this.props.history.push(`/project/list/`);
    }
    setTitle(title){
        this.setState({title});
    }
    render(){
        return (
            <div class="page-content">
                <section class="no-padding-top">
                    <div class="container-fluid">
                        <QuizSettings
                            title={this.state.title}
                            setTitle={this.setTitle}
                        />
                        <Config/>
                        <Script/>
                    </div>
                </section>
            </div>
        )
    }
} 
const mapDispatchToProps = dispatch => ({
   
})
const mapStateToProps = state => ({
    currentQuiz: state.test.current,
    list:state.test.list
})
export default connect(mapStateToProps, mapDispatchToProps)(Setting);