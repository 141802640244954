import React, {Component} from "react";
import { connect } from "react-redux";
import { get, remove, set, copy } from "./../../../actions/test";
import { allList } from "./../../../actions/object";
import Loading from "./../../../components/layouts/loading";


class TestList extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false,
        }
        this.renderRow = this.renderRow.bind(this);
    }
    async componentWillMount(){
        this.setState({loading:true});
        await this.props.getTests();
        this.setState({loading:false});
    }
    async copy(id){
        this.setState({loading:true});
        await this.props.copyTest(id);
        await this.props.getTests();
        this.setState({loading:false});
    }
    async remove(id){
        this.setState({loading:true});
        await this.props.removeTest(id);
        await this.props.getTests();
        this.setState({loading:false});
    }
    async set(id){
        this.setState({loading:true});
        await this.props.setQuiz(id);
        await this.props.allList();
        this.setState({loading:false});
        this.props.history.push("/quiz");
    }
    renderRow(test, i){
        return (
            <div class="col-md-3 col-sm-6">
            
            <div class="statistic-block block">
            
              <div class="progress-details d-flex align-items-end justify-content-between">
                <div class="title">
                    <div class="icon"><i class="icon-user-1"></i></div><strong>{test.title}</strong> 
                </div>
              </div>
              <div class="justify-content-between">
                   <button
                        onClick={()=>this.set(test.id)} 
                        type="button" 
                        class="btn btn-primary btn-small">Редактировать</button>
              
                    <button
                        onClick={()=>this.props.history.push("/order/"+test.id)} 
                        type="button" 
                        class="btn btn-primary btn-small btn-margin">Заявки</button>
                     <button
                        onClick={()=>this.copy(test.id)} 
                        type="button" 
                        class="btn btn-primary btn-small btn-margin-right">Скопировать</button>
                     <button
                        onClick={()=>this.remove(test.id)} 
                        type="button" 
                        class="btn btn-primary btn-small ">Удалить</button>
              </div>
            </div>
          </div>
        )
        {/* <Link to={"/test/edit/"+test.id}>Редактировать</Link>
                    <br/>
                    <a href="#" onClick={()=>this.remove(test)}>Удалить</a> */}
    }
    render(){
        if(this.state.loading) return <Loading/>;
        return (
            <div class="page-content">
                <div class="page-header">
                    <div class="container-fluid">
                        <h2 class="h5 no-margin-bottom">Проекты</h2>
                    </div>
                </div>
   
            <section class="no-padding-top">
           
            <div class="container-fluid">
                <button
                     onClick={()=>this.props.history.push("/project/create")} 
                    type="button" 
                    class="btn btn-primary btn-big">Добаить квиз</button>
                <div class="row">
                     {this.props.list && this.props.list.map(this.renderRow)}
                </div>
            </div>
            </section>
        </div>
        )
    }
}
const mapDispatchToProps = dispatch => ({
    getTests:()=>dispatch(get()),
    removeTest:(id)=>dispatch(remove(id)),
    copyTest:(id)=>dispatch(copy(id)),
    setQuiz:(id)=>dispatch(set(id)),
    allList:()=>dispatch(allList())
})
const mapStateToProps = state => ({
    list:state.test.list
})
export default connect(mapStateToProps, mapDispatchToProps)(TestList);