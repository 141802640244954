import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getToken } from "./../../actions/user";
import { getCurrent } from "./../../actions/test";
import { list as getCategoryList } from "./../../actions/category";
import Login from "./../../pages/login";
import Loading from "./../layouts/glLoading";
import Main from '../main';

class Boot extends Component {
  constructor(props){
    super(props);
    this.state = {
        loading:true
    }
  }
  async componentWillMount(){
    await this.props.getToken(); 
    if(this.props.token){
      await this.props.getCategoryList();
     // await this.props.getCurrent();
    }
    this.setState({loading:false})
  }
  async componentWillReceiveProps(nextProps){
    if(this.props.token != nextProps.token){
      this.setState({loading:true})
      await this.props.getCategoryList();
      this.setState({loading:false})
    }
  }
  render() {
    if(this.state.loading) 
        return <Loading />
    if(this.props.token) return <Main/>;
    return <Login/>;
  }
}
const mapDispatchToProps = dispatch => ({
    getToken: ()=>dispatch(getToken()),
    getCategoryList:()=>dispatch(getCategoryList()),
    getCurrent:()=>dispatch(getCurrent())
});

const mapStateToProps = state => ({
    token: state.user.token
})

export default connect(mapStateToProps, mapDispatchToProps)(Boot);
