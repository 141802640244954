import React, { PureComponent } from 'react';
import { logout } from "./../../../actions/user";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {withRouter} from 'react-router';

class Header extends PureComponent {
  constructor(props){
    super(props);
    this.logout = this.logout.bind(this);
    this.back = this.back.bind(this);
  }
  async logout(){
    await this.props.logout();
  }
  back(){
    this.props.history.goBack()
  }
  render() {
    return (
        <header class="header">   
        <nav class="navbar navbar-expand-lg">
          <div class="search-panel">
            <div class="search-inner d-flex align-items-center justify-content-center">
              <div class="close-btn">Close <i class="fa fa-close"></i></div>
              <form id="searchForm" action="#">
                <div class="form-group">
                  <input type="search" name="search" placeholder="What are you searching for..."/>
                  <button type="submit" class="submit">Search</button>
                </div>
              </form>
            </div>
          </div>
          <div class="container-fluid d-flex align-items-center justify-content-between">
            <div class="navbar-header">
            <Link to="/" class="navbar-brand">
                <div class="brand-text brand-big visible text-uppercase"><strong class="text-primary">stone</strong><strong>tech</strong></div>
                <div class="brand-text brand-sm"><strong class="text-primary">s</strong><strong>t</strong></div></Link>
             
              <button onClick={this.back} class="sidebar-toggle"><i class="fa fa-long-arrow-left"></i></button>
              <button onClick={()=>this.props.history.push("/settings")} class="sidebar-toggle"><i class="fa fa-cog"></i></button>
            </div>
            <div class="right-menu list-inline no-margin-bottom">    
              <div class="list-inline-item logout">                   
              <a id="logout" href="#" onClick={this.logout} class="nav-link">Logout <i class="icon-logout"></i></a></div>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})
const mapStateToProps = state => ({

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));