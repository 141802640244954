import { MISSING_REQUIRED_PARAMS, INCORRECT_OLD_PASSWORDS } from "./../../resources/errors";
import ERRORS from "./../../resources/errors";
import { nFetchPost, nFetchPostWithBearer } from "./../../lib/network";

const URL = 'user/';

export const LOGIN_FAILED = "LOGIN_FAILED";
export const SET_TOKEN = "SET_TOKEN";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export function login(username, password) {
    return async (dispatch) => {
        if (!username.length || !password.length) return dispatch({
            type: LOGIN_FAILED,
            payload: MISSING_REQUIRED_PARAMS
        });
        else {
            return nFetchPost("login", {
                username,
                password
            }).then((res) => {
                if (res.result) {
                    saveToken(res.payload.token);
                    return dispatch({
                        type: SET_TOKEN,
                        payload: res.payload.token
                    });
                }
                else
                    return dispatch({
                        type: LOGIN_FAILED,
                        payload: ERRORS[res.error]
                    });
            }).catch((err) => { });
        }
    }
}
export function setPass(new_pass_1, new_pass_2,old_pass) {
    return async (dispatch) => {
        if (!new_pass_1.length || !new_pass_2.length || !old_pass.length) return dispatch({
            type: LOGIN_FAILED,
            payload: MISSING_REQUIRED_PARAMS
        });
        if(new_pass_1 != new_pass_2) return dispatch({
            type: LOGIN_FAILED,
            payload: INCORRECT_OLD_PASSWORDS
        });
        else {
            return nFetchPostWithBearer("user/setPassword", {
                old_password:old_pass,
                new_password:new_pass_1
            }).then((res) => {
                if (res.result) {
                    saveToken(res.payload.token);
                    return dispatch({
                        type: SET_TOKEN,
                        payload: res.payload.token
                    });
                }
                else
                    return dispatch({
                        type: LOGIN_FAILED,
                        payload: ERRORS[res.error]
                    });
            }).catch((err) => {console.log(err); });
        }
    }
}

export function getToken() {
    try {
        const token = localStorage.getItem("token");
        if (token)
            return {
                type: SET_TOKEN,
                payload: localStorage.getItem("token")
            };
        else
            return { type: INVALID_TOKEN };
    }
    catch (err) {
        console.log(err);
        return { type: INVALID_TOKEN };
    }
}

export function saveToken(token) {
    localStorage.setItem("token", token);
}
export function logout(){
    localStorage.clear();
    return {
        type:LOG_OUT
    }

}
export function resetPass(username, email){
    return async (dispatch)=>{
        if (!username.length || !email.length) return dispatch({
            type: LOGIN_FAILED,
            payload: MISSING_REQUIRED_PARAMS
        });
        return nFetchPost("user/resetPassword", {
           username,
           email
        }).then((res) => {
            if (res.result) {
                return dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                });
            }
            else
                return dispatch({
                    type: LOGIN_FAILED,
                    payload: ERRORS[res.error]
                });
        }).catch((err) => {console.log(err); });
    }
}