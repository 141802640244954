import ERRORS from "./../../resources/errors";
import { nFetchPostWithBearer } from "./../../lib/network";

const URL = 'category/';

export const CATEGORY_LIST = "CATEGORY_LIST";
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR";

export function list() {
    return async (dispatch) => {
        return nFetchPostWithBearer(URL+"list", {})
            .then((res) => {
                console.log("res",res);
                if (res.result) {                    
                    return dispatch({
                        type: CATEGORY_LIST,
                        payload: res.payload.categories
                    });
                }
                else
                    return dispatch({
                        type: CATEGORY_LIST_ERROR,                            
                        payload: ERRORS[res.error]
                    });
            }).catch((err) => { console.log("err",err) });
    }
}

