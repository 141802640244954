import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { get } from "./../../actions/test";
import { getByUser } from "./../../actions/order";
import { connect } from "react-redux";
import Loading from "./../../components/layouts/loading";
import {Doughnut} from 'react-chartjs-2';

class Home extends Component {
    constructor(props){
      super(props);
      this.state = {
        loading:true
      }
      this.renderTest = this.renderTest.bind(this);
    }
    renderTest(test){
      console.log(test);
      let count = 0, sum=0;
      let currency = null;
      for(let i in this.props.order_list){
        if(this.props.order_list[i].quiz_id == test.id){
          count++;
          sum+=this.props.order_list[i].sum;
        }
        if(!currency && this.props.order_list[i].currency) currency = this.props.order_list[i].currency == "rub" ? "руб." : "грн.";
      }
      return <Fragment>
        
         
           
          
         
        
          <Link to={"/order/"+test.id}  class="col-md-3 col-sm-6">
          <h2 class="h5 no-margin-bottom">{test.title}</h2>
                <div class="statistic-block block">
                  <div class="progress-details d-flex align-items-end justify-content-between">
                    <div class="title">
                    <div class="icon"><i class="icon-contract"></i></div><strong>Заявки</strong>
                    </div>
                    <div class="number dashtext-2">{count}</div>
                  </div>
                  <div class="progress progress-template">
                    <div role="progressbar"  aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" class="progress-bar progress-bar-template dashbg-2"></div>
                  </div>
                </div>
                <div class="stats-with-chart-2 block">
                  <div class="title"><strong class="d-block">{test.title}</strong><span class="d-block">Общая сумма заказов</span></div>
                  <div class="piechart chart">
                  <Doughnut type={'doughnut'}
        options={{
            cutoutPercentage: 90,
            legend: {
                display: false
            }
        }} data={  {
            labels: [
                ""
            ],
            datasets: [
                {
                    data: [String(sum)],
                    borderWidth: [0],
                    backgroundColor: [
                        '#9528b9',
                       
                    ],
                    hoverBackgroundColor: [
                        '#9528b9',
                       
                    ]
                }]
        }} />
                    <div class="text"><strong class="d-block chart-text">{sum}</strong>
                     <span class="d-block"> {currency}</span>
                    </div>
                  </div>
                </div>
              </Link>
          
        </Fragment>
    }
    async componentWillMount(){
      await this.props.getTests();
      await this.props.getOrders();
      this.setState({loading:false})
    }
    render() {
      if(this.state.loading) return <Loading/>;
        return (
        <div class="page-content">
        <div class="page-header">
          <div class="container-fluid">
            <h2 class="h5 no-margin-bottom">Главная</h2>
          </div>
        </div>
        <section class="no-padding-top no-padding-bottom">
          <div class="container-fluid">

            <div class="row">
              <Link to={"/order"} class="col-md-3 col-sm-6">
             
                <div class="statistic-block block">
                  <div class="progress-details d-flex align-items-end justify-content-between">
                    <div class="title">
                     <div class="icon"><i class="icon-user-1"></i></div><strong>Заявки</strong>
                    </div>
                    <div class="number dashtext-1">{this.props.order_list.length}</div>
                  </div>
                  <div class="progress progress-template">
                    <div role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" class="progress-bar progress-bar-template dashbg-1"></div>
                  </div>
                
                </div>
              
              </Link>
              <Link to={"/project/list"} class="col-md-3 col-sm-6">
                <div class="statistic-block block">
                  <div class="progress-details d-flex align-items-end justify-content-between">
                    <div class="title">
                   <div class="icon"><i class="icon-contract"></i></div><strong>Проекты</strong>
                    </div>
                    <div class="number dashtext-2">{this.props.test_list.length}</div>
                  </div>
                  <div class="progress progress-template">
                    <div role="progressbar"  aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" class="progress-bar progress-bar-template dashbg-2"></div>
                  </div>
                </div>
              </Link>
              {/* <div class="col-md-3 col-sm-6">
                <div class="statistic-block block">
                  <div class="progress-details d-flex align-items-end justify-content-between">
                    <div class="title">
                    <Link to={"/project/list"}><div class="icon"><i class="icon-contract"></i></div><strong>Сумма просчетов</strong></Link>
                    </div>
                    {this.props.order_rub ? this.props.order_rub + " руб." : null}, {this.props.order_uan ? this.props.order_uan + " грн." : null}
                  </div>
                  <div class="progress progress-template">
                    <div role="progressbar"  aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" class="progress-bar progress-bar-template dashbg-2"></div>
                  </div>
                </div>
              </div> */}
            </div>
            <div class="row">
                {this.props.test_list.map(this.renderTest)}
            </div>
          </div>
        </section>
        <footer class="footer">
          {/* <div class="footer__block block no-margin-bottom">
            <div class="container-fluid text-center">
            
              <p class="no-margin-bottom">2018 &copy; Your company. Design by <a href="https://bootstrapious.com">Bootstrapious</a>.</p>
            </div>
          </div> */}
        </footer>
    </div>
    )
    }
}
const mapDispatchToProps = dispatch => ({
  getTests:()=>dispatch(get()),
  getOrders:()=>dispatch(getByUser())
})
const mapStateToProps = state => ({
  test_list:state.test.list,
  order_list:state.order.list,
  order_rub:state.order.rub,
  order_uan:state.order.uan,
  order_currency:state.order.currency
})
export default connect(mapStateToProps, mapDispatchToProps)(Home);