import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./../../../components/layouts/loading";
import { create, resetList } from "./../../../actions/object";
import ObjectItem from "./../../../components/object/item";
import lodash from "lodash";

class Create extends Component{
    constructor(props){
        super(props);
        this.state = {
            data: null,
            loading:true
        }
        this.submit = this.submit.bind(this);
    }
    async componentWillMount(){
        if(!this.props.currentQuiz) this.props.history.push(`/project/list/`);
        this.category = await this.getCategoryById(this.props.match.params.category_id);

        this.toggleLoading();
    }
    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
    async submit(result){
        this.toggleLoading();
        await this.props.create(result,this.category.params,this.props.match.params.category_id);
        if(!this.props.objectError){
            await this.props.resetList()
            this.props.history.push(`/quiz/object/list/`+this.category.id)
        }
        else  this.toggleLoading();
    }
    getCategoryById(id:number){
        let category = lodash.pickBy(this.props.categoryList,(cat)=>cat.id==id);
        return category[Object.keys(category)[0]];
    }
    async componentWillReceiveProps(nextProps){
        if(nextProps.match.params.category_id != this.props.match.params.category_id){
            this.toggleLoading();
            this.category = await this.getCategoryById(nextProps.match.params.category_id );
            this.toggleLoading();
        }
    }
    render(){
        return <ObjectItem 
                submit={this.submit}
                loading={this.state.loading}
                img_required={true}
                category={this.category}
                error={this.props.objectError}/>
    }
}
const mapDispatchToProps = dispatch => ({
    create:(params,templateParams,id)=>dispatch(create(params,templateParams,id)),
    resetList:()=>dispatch(resetList())
})
const mapStateToProps = state => ({
    categoryList: state.category.list,
    objectError: state.object.error,
    currentQuiz: state.test.current
})
export default connect(mapStateToProps, mapDispatchToProps)(Create);