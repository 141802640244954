import { SERVER_URL } from "./../resources/config";
import axios from "axios";

export const validateObject = (object, template, isEdit = false)=>{
    let result = true;
    for(const j in template){
        let isExist = false;
        switch(template[j].type){
            case "text":{
                if(object[template[j].name]){
                    isExist = true;
                    template[j].value = object[template[j].name];
                }
            }
            case "price":{
                if(object['price']){
                    isExist = true;
                    template[j].value = object['price'];   
                }
                break;
            }
            case "category":{
                if(object["values_"+template[j].id] && object["values_"+template[j].id].length){
                    for(const item of object["values_"+template[j].id]){
                        if(!item) continue;
                        if(item.id && item.length){
                            isExist = true;
                            delete template[j].title;
                            delete template[j].objects;
                            template[j].values = object["values_"+template[j].id];
                        }
                    }
                }
                break;
            }
        }
        if(!isExist){
            result = false;
            break;
        }
    }
    if(result && object.title && object.backend_title && (isEdit || (object.img && object.prev_img))){
        let response = {};
        response.params = template;
        response.backend_title = object.backend_title;
        response.title = object.title;
        response.img = isEdit && !object.img ? null : object.img;
        response.prev_img = isEdit && !object.prev_img ? null : object.prev_img;
        return response;
    }
    return false;
}

export const validateTest = (test,start,end)=>{
    let isErrorExist = false;
    for(let item of test){
        if(!item) continue;
        if(!item.title || 
           // !item.subTitle || 
           // !item.label ||
          //  !item.helper_text || 
            // !(item.prev || item.id == start || item.id == end) || 
            (!item.type || 
                (item.type == 2 && item.values.length == 0) ||
                (item.type == 1 && item.params.length == 0))){
            isErrorExist = true;
            break;
        }
    }
    return !isErrorExist;
}

export const uploadPdfFile = async (data)=>{
    let ok = true;
    for(const i in data){
        if(data[i].pdf){
            let result = await uploadFile(data[i].pdf,false);

            if(result){
                data[i].pdf = result;
            }
            else{
                ok = false;
                break;
            }
        }
    }
    if(ok) return data;
    else return false;
}

export const uploadFile = async (file, randomName = true)=>{
    const data = new FormData();
    let fileName = file.name;
    if(randomName) fileName = Array(32).fill(null).map(() => (Math.round(Math.random() * 16)).toString(16)).join('')+"."+file.type.split("/")[1];
    data.append('file',file, fileName);
    return axios
      .post(SERVER_URL +"object/uploadImage", data, {})
      .then(res => {
        return fileName;
      }).catch((err)=>{
          console.log(err)
          return false;
      })
}
export const validateEmail = (email)  =>{
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}