import ERRORS, { MISSING_REQUIRED_PARAMS  } from "./../../resources/errors";
import { nFetchPostWithBearer } from "./../../lib/network";


const URL = 'order/';

export const ORDER_OK = "ORDER_OK";
export const ORDER_SET_ERROR = "ORDER_SET_ERROR";
export const ORDER_LIST_BY_QUIZ ="ORDER_LIST_BY_QUIZ";


export function getByQuiz(id) {
    return async (dispatch, getState) => {
        console.log("ID",id);
        return nFetchPostWithBearer(URL+"getByQuiz", {
            quiz_id: id
        }).then((res) => {
            if (res.result) {
                let orders = res.payload.orders;  
                for(const i in orders) orders[i].type = orders[i].type  == 1 ? "Сделал просчет" : "Заявка на звонок";             
                return dispatch({
                    type: ORDER_LIST_BY_QUIZ,
                    orders
                });
            }
            else
                return dispatch({
                    type: ORDER_SET_ERROR,                            
                    error: ERRORS[res.error]
                });
            }).catch((err) => { });
    }
}

export function getByUser() {
    return async (dispatch, getState) => {
        return nFetchPostWithBearer(URL+"getByUser", {}).then((res) => {
            if (res.result) { 
                let sum = 0;
                let orders = res.payload.orders; 
                let rub = 0, uan = 0;
                for(const i in orders){
                    orders[i].type = orders[i].type  == 1 ? "Сделал просчет" : "Заявка на звонок";  
                    if(orders[i].currency == "rub") rub+=orders[i].sum;
                    else uan+=orders[0].sum;
                }         
                return dispatch({
                    type: ORDER_OK,
                    orders,
                    rub,
                    uan
                });
            }
            else
                return dispatch({
                    type: ORDER_SET_ERROR,                            
                    error: ERRORS[res.error]
                });
            }).catch((err) => {  });
    }
}