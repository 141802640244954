import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./../../../components/layouts/loading";
import {  get, create,  list, resetList } from "./../../../actions/object";
import ObjectItem from "./../../../components/object/item";
import lodash from "lodash";

class Copy extends Component{
    constructor(props){
        super(props);
        this.state = {
            data: null,
            loading:true
        }
        this.submit = this.submit.bind(this);
    }
    async componentWillMount(){
        if(!this.props.currentQuiz) this.props.history.push(`/project/list/`);
        await this.props.getObject(this.props.match.params.object_id);
        if(this.props.object){
            this.category =  this.getCategoryById(this.props.object.category_id)
            const data = this.makeDataObject(this.props.object);
            this.setState({
                data
            })
           this.makeRenderData();
        }
        else this.toggleLoading();
    }
    makeDataObject(object){
        let result = {};
        result.title = object.title;
        result.backend_title = object.backend_title;
        result.imgSrc = object.imgSrc;
        result.previewImgSrc = object.previewImgSrc;
        const params = JSON.parse(object.params);
        for(const i in params){
            const param = params[i];
            if(param.type == "category"){
                result["values_"+param.id] = param.values;
            }
            else if(param.type == "price"){
                result[param.type] = param.value;
            }
            else result[param.name] = param.value;
        }
        return result;
    }
    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
    async submit(result){
        this.toggleLoading();
        result.img = this.props.object.imgSrc;
        result.prev_img = this.props.object.previewImgSrc;
        await this.props.create(result,this.category.params,this.props.object.category_id);
        if(!this.props.objectError){
            await this.props.resetList()
            this.props.history.push(`/quiz/object/list/`+this.category.id)
        }
        else  this.toggleLoading();
    }
    getCategoryById(id:number){
        let category = lodash.pickBy(this.props.categoryList,(cat)=>cat.id==id);
        return category[Object.keys(category)[0]];
    }
    async makeRenderData(){
        const params = this.category.params;
        for(const i in params){
            if(params[i].type == "category"){
                await this.props.getObjectList(params[i].id);
                if(!this.props.objectError){
                    params[i].objects = this.props.objectList;
                }
                let category = lodash.pickBy(this.props.categoryList,(cat)=>cat.id==params[i].id);
                params[i].title = category[Object.keys(category)[0]].title;

            }
        }
        this.toggleLoading();
    }
    async componentWillReceiveProps(nextProps){
        if(nextProps.match.params.object_id != this.props.match.params.object_id){
            this.toggleLoading();
            await this.props.getObject(nextProps.match.params.object_id);
            if(this.props.object){
                this.category =  this.getCategoryById(nextProps.object.category_id)
                const data = this.makeDataObject(nextProps.object);
                this.setState({
                    data
                })
               this.makeRenderData();
            }
            else this.toggleLoading();
        }
    }
    render(){
        return <ObjectItem 
                submit={this.submit}
                loading={this.state.loading}
                data={this.state.data}
                category={this.category}
                error={this.props.objectError}/>
    }
}
const mapDispatchToProps = dispatch => ({
    getObject:(id)=>dispatch(get(id)),
    create:(params,templateParams,id)=>dispatch(create(params,templateParams,id, true)),
    getObjectList:(id)=>dispatch(list(id)),
    resetList:()=>dispatch(resetList())
})
const mapStateToProps = state => ({
    object:state.object.item,
    categoryList: state.category.list,
    objectError: state.object.error,
    currentQuiz: state.test.current
})
export default connect(mapStateToProps, mapDispatchToProps)(Copy);