import React, { PureComponent, Fragment } from 'react';
import { connect } from "react-redux";
import { create as createObject, list } from "./../../actions/object";
import lodash from "lodash";
import Loading from "./../../components/layouts/loading";

class Category extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            title:"",
            img:null,
            loading:false
        }
        this.category = {};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderParam = this.renderParam.bind(this);
        this.fileInput = React.createRef();
    }
    async componentWillMount(){
        this.getCategoryById(this.props.match.params.id);
        await this.makeRenderData();
    }
    async makeRenderData(){
        await this.toggleLoading();
        const params = this.category.params;
        for(const i in params){
            if(params[i].type == "category"){
                await this.props.getObjectList(params[i].id);
                if(!this.props.objectError){
                    params[i].objects = this.props.objectList;
                }
                let category = lodash.pickBy(this.props.categoryList,(cat)=>cat.id==params[i].id);
                params[i].title = category[Object.keys(category)[0]].title;
            }
        }
        await this.toggleLoading();
    }
    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
    getCategoryById(id:number){
        let category = lodash.pickBy(this.props.categoryList,(cat)=>cat.id==id);
        this.category = category[Object.keys(category)[0]];
        // this.category.params.forEach((param)=>{
        //     if(param.type == "category"){
                
        //     }  
        // });
        // this.setState({

        // })
        this.forceUpdate();
    }
    async handleSubmit(event){
        this.toggleLoading();
        event.preventDefault();
        await this.props.createObject(this.state,this.category.params,this.category.id);
        if(!this.props.objectError) this.props.history.push(`/object/list/`+this.props.match.params.id)
        else this.toggleLoading();
    }
    handleChange(event) {
        const target = event.target;
        let value=target.value;
        if(target.type == 'file'){
            value = target.files[0];
        }
        const name = target.name;
        this.setState({
          [name]: value
        });
    }
    async componentWillReceiveProps(nextProps){
        if(nextProps.match.params.id != this.props.match.params.id){
            this.getCategoryById(nextProps.match.params.id);
            await this.makeRenderData();
        }
    }
    renderParam(param:array,i){
        let result;
        switch(param.type){
            case "price":{
                result =  (
                    <Fragment key={i}>
                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Цена</label>
                            <div class="col-sm-9">
                                <input
                                    onChange={this.handleChange} 
                                    type="text"
                                    name="price" 
                                    value={this.state.price}
                                    class="form-control"/>
                            </div>
                        </div>
                        <div class="line"></div>
                    </Fragment>);
                break;
            }
            case "category":{
                result = (
                    <Fragment key={i}>
                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">{param.title}</label>
                            <div class="col-sm-9">
                                 <select
                                    onChange={this.handleChange} 
                                    name={i+"_"+param.id} 
                                    value={this.state[i+"_"+param.id]}
                                    class="form-control mb-3 mb-3">
                                     <option value={0}>Не выбрано</option>
                                    {param.objects && param.objects.map((object,i)=>
                                        <option value={object.id}>{object.title}</option>)}
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Количество {param.title}</label>
                            <div class="col-sm-9">
                                <input
                                    onChange={this.handleChange} 
                                    type="text"
                                    name={i+"_"+param.id+"_count"} 
                                    value={this.state[i+"_"+param.id+"_count"]}
                                    class="form-control"/>
                            </div>
                        </div>
                        <div class="line"></div>
                    </Fragment>);
            }
        }
        return result;
    }
    render() {
        if(this.state.loading) return <Loading/>
        return (
            <div class="page-content">
                <section class="no-padding-top">
                 <div class="container-fluid">
                <div class="col-lg-12">
                    <div class="block">
                    <div class="title"><strong>Создать объект категории {this.category.title}</strong></div>
                    {this.props.objectError.length ? <p>{this.props.objectError}</p> : null}
                    <div class="block-body">
                         <form
                            onSubmit={this.handleSubmit} 
                            class="form-horizontal">
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Название</label>
                                <div class="col-sm-9">
                                    <input
                                        onChange={this.handleChange} 
                                        type="text"
                                        name="title" 
                                        value={this.state.title}
                                        class="form-control"/>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Картинка</label>
                                <div class="col-sm-9">
                                    <input
                                        onChange={this.handleChange} 
                                        type="file"
                                        name="img" 
                                        ref={this.fileInput}
                                      //  value={this.img}
                                        class="input-file"/>
                                </div>
                            </div>
                            <div class="line"></div>
                            {this.category && this.category.params.map(this.renderParam)}
                        <div class="form-group row">
                            <div class="col-sm-9 ml-auto">
                            {/* <button type="submit" class="btn btn-secondary">Cancel</button> */}
                            <button type="submit" class="btn btn-primary">Добавить</button>
                            </div>
                        </div>
                        </form>
                      
                        </div>
                    </div>
                    </div> 
                 </div>
                </section>
            </div>
            )
    }
}
const mapDispatchToProps = dispatch => ({
    createObject:(params,templateParams, id)=>dispatch(createObject(params,templateParams,id)),
    getObjectList:(id)=>dispatch(list(id))
})
const mapStateToProps = state => ({
    categoryList: state.category.list,
    objectList:state.object.list,
    objectError: state.object.error
})
export default connect(mapStateToProps, mapDispatchToProps)(Category);