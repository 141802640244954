import { combineReducers } from 'redux';
import user from './user';
import category from './category';
import object from './object';
import test from "./test";
import config from "./config";
import order from "./order";
export default combineReducers({
 user,
 category,
 object,
 test,
 config,
 order
});