import React, { PureComponent } from 'react';
import ReactLoading from 'react-loading';

export default class Loading extends PureComponent {
    render() {
        return (
            <div class="page-content">
                <section class="no-padding-top">
                    <div class="container-fluid">
                        <div class="wrap-loading">
                            <ReactLoading  
                                type={"spinningBubbles"}
                                color={" #DB6574"} 
                                height={'5%'} 
                                width={'5%'} />
                        </div>
                </div>
            </section>
            </div>
        )
    }
}
