import React, { PureComponent, Fragment } from 'react';
import { connect } from "react-redux";
import { saveHelper, get } from "./../../../actions/test";
import Loading from "./../../../components/layouts/loading";
import { SERVER_URL } from "./../../../resources/config";
import lodash from "lodash";

class Helper extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            name:"",
            avatar:null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    async componentWillMount(){
        if(!this.props.currentQuiz) this.props.history.push(`/project/list/`);
        this.updateData()
      
    }
    updateData(){
        const tmp = lodash.pickBy(this.props.list,(q)=>q.id==this.props.currentQuiz);
        const quiz = tmp[Object.keys(tmp)[0]];
        let name = "", avatarSrc=null;
        if(quiz && quiz.helper && quiz.helper[0]){
            name = quiz.helper[0].name;
            avatarSrc = quiz.helper[0].avatarSrc;
        }   
        this.setState({
            name,
            avatarSrc
        })
    }
    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
   async handleSubmit(event){
        this.toggleLoading();
        event.preventDefault();
        await this.props.saveHelper(this.state.name,this.state.file,!!this.state.avatarSrc);
        // if(!this.props.error){
        //     await this.props.getList();
        //     this.updateData()
        // }
        this.toggleLoading();
    }
    handleChange(event){
        const target = event.target;
        let value = target.type === 'file' ? target.files[0] : target.value;
        this.setState({[target.name]:value})
     }
    render() {
        if(this.state.loading) return <Loading/>
        return (
            <div class="page-content">
            <section class="no-padding-top">
                <div class="container-fluid">
                    <div class="block">
                    <div class="title"><strong>Настройки помощника</strong></div>
                    {this.props.error && this.props.error.length ? <p>{this.props.error}</p> : null}
                    <div class="block-body">
                         <form
                            onSubmit={this.handleSubmit} 
                            class="form-horizontal">
                            <Fragment>
                                <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Имя</label>
                                    <div class="col-sm-12">
                                            <input
                                                onChange={this.handleChange} 
                                                type="text"
                                                name={"name"} 
                                                value={this.state.name}
                                                class={this.props.error && !this.state.name ? "form-control is-invalid" : "form-control"}/>
                                    </div>
                                </div>
                            <div class="line"></div>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Аватар</label>
                                <div class="col-sm-12">
                                    {this.state.avatarSrc &&
                                        <Fragment>
                                        
                                            <div class="col-sm-2">
                                                <img class="object-preview-img"  src={SERVER_URL+(this.props.avatarSrc || this.state.avatarSrc)}/>
                                            </div>  
                                        </Fragment>}
                                    <div class="line"></div>
                                    <div class="col-sm-2">
                                        <input
                                            onChange={this.handleChange} 
                                            type="file"
                                            name="file"
                                            class={this.props.error && !this.state.img ? "input-file file-invalid" : "input-file"}/>
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            </Fragment>
                            <div class="form-group row">
                            <div class="col-sm-9 ml-auto">
                                <button type="submit" class="btn btn-primary">Сохранить</button>
                            </div>
                        </div>
                        </form>
                        </div>
                    </div>
                    </div> 
                </section>
            </div>
            )
    }
}
const mapDispatchToProps = dispatch => ({
    saveHelper:(name,avatar, isFileExist)=>dispatch(saveHelper(name,avatar,isFileExist)),
    getList:()=>dispatch(get())
})
const mapStateToProps = state => ({
    currentQuiz:state.test.current,
    list:state.test.list,
    error:state.test.error,
    avatarSrc:state.test.new_avatar_src
})
export default connect(mapStateToProps, mapDispatchToProps)(Helper);
      