import React,{ Component } from "react";
import { saveCatalog, get } from "./../../../actions/test";
import { connect } from "react-redux";
import Loading from "./../../../components/layouts/loading";
import { SERVER_URL } from "./../../../resources/config";
import lodash from "lodash";

class Gain extends Component{
    constructor(props){
        super(props);
        this.state = {
            pdf:null,
            loading:false
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillMount(){
        if(!this.props.currentQuiz) this.props.history.push(`/project/list/`);
        this.setState({loading:true})
        this.updateFileSrc();
        this.setState({loading:false})
    }
    updateFileSrc(){
        let test = lodash.pickBy(this.props.testList,(_)=>_.id == this.props.currentQuiz);
        test = test[Object.keys(test)[0]];
        if(test){
            this.catalog_src = null;
            if(test.catalog_src){
                this.catalog_src = test.catalog_src;
            }
        }
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'file' ? target.files[0] : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }
    async upload(){
        this.setState({loading:true})
        await this.props.saveCatalog(this.state.pdf);
        if(!this.props.error){
            await this.props.list();
            this.updateFileSrc();
        }
        this.setState({loading:false})
    }
    render(){
        if(this.state.loading) return <Loading/>
        return (
            <div class="page-content">
                <div class="col-lg-12">
                    <div class="block">
                    <div class="title"><strong>Усиление</strong></div>
                        {this.props.error && this.props.error.length ? <p>{this.props.error}</p> : null}
                        <div class="block-body">
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Файл католога</label>
                                    <div class="col-sm-2">
                                        <input
                                            onChange={this.handleChange} 
                                            type="file"
                                            name="pdf"
                                            class="input-file"/>
                                    </div>
                            </div>
                            <div class="line"></div>
                            {this.catalog_src && <div class="form-group row">
                               
                            <label class="col-sm-3 form-control-label"></label>
                                        <div class="col-sm-2 block-el">
                                            <a href={SERVER_URL+this.catalog_src}>{this.catalog_src}</a>
                                        </div>
                                    
                                  
                            </div>}
                            <div class="line"></div>
                                <button
                                    onClick={()=>this.upload()} 
                                    type="button" 
                                    class="btn btn-primary">Загрузить</button>
                        </div>
                    </div>
                </div> 
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => ({
    saveCatalog:(file)=>dispatch(saveCatalog(file)),
    list:()=>dispatch(get())
})
const mapStateToProps = state => ({
    error:state.test.error,
    currentQuiz:state.test.current,
    testList:state.test.list
})
export default connect(mapStateToProps, mapDispatchToProps)(Gain);