import React, { Component, Fragment } from "react";
import lodash from "lodash";

export default class CategoryType extends Component{
    constructor(props){
        super(props);
        this.state = {
            selected: null
        }
        this.addObject = this.addObject.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    addObject(){
        this.props.addObject(this.props.id,this.state.selected);
    }
    handleChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
          [name]: value
        });
    }
    
    render(){
        return (
            <Fragment>
                <div class="form-group row">
                        <label class="col-sm-3 form-control-label">{this.props.title}</label>
                            <div class="col-sm-4">
                                 <select
                                    onChange={this.handleChange} 
                                    name={"selected"} 
                                    value={this.state.selected}
                                    class="form-control mb-3 mb-3">
                                    <option value={0}>-</option>
                                    {lodash.size(this.props.objects) && this.props.objects.map((object,i)=>
                                        <option value={object.id}>{object.backend_title}</option>)}
                                </select>
                            </div>
                            <div class="col-sm-4">
                                <button type="button" onClick={this.addObject} class="btn btn-primary">Добавить</button>
                            </div>
                        </div>
    
                            {this.props.values && this.props.values.map((object,i)=>{
                                let tmp = lodash.pickBy(this.props.objects,(item)=>item.id ==object.id);
                                tmp =  tmp[Object.keys(tmp)[0]];
                                if(!tmp) return null;
                                const title = tmp.backend_title;
                                return (
                                    <Fragment>
                                        <div class="form-group row">
                                        <label class="col-sm-3 form-control-label"></label>
                                         <div class="col-sm-2">
                                            <p>{title}</p>
                                        </div>
                                        <div class="col-sm-3 form-group row">
                                            <input
                                                onChange={(event)=>this.props.handleLengthChange(this.props.id,object.id,event.target.value)}
                                                type="text"
                                                name="title"
                                                class={this.props.error && !object.length ? "form-control is-invalid" : "form-control"}
                                                placeholder="Количество"
                                                value={object.length}
                                               />
                                        </div>
                                        <div class="col-sm-2">
                                            <button type="button" onClick={()=>this.props.removeObject(this.props.id,object.id)} class="btn btn-primary button-center">Удалить</button>
                                        </div>
                                        </div>
                                    </Fragment>
                                )  
                            })}
            </Fragment>
        )
    }
}