import React, { PureComponent } from 'react';
import Header from "./../layouts/header";
import Aside from "./../layouts/aside";
import Router from "./../layouts/router";
import { BrowserRouter } from 'react-router-dom';

export default class Main extends PureComponent {
  render() {
    return (
        <div>
          <BrowserRouter>
          <div>
          <Header/>
          <div class="d-flex align-items-stretch">
          <Aside/>
          <Router/>
         </div>
        </div> 
         </BrowserRouter>
        </div>
    )
  }
}

