import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { saveResultPage } from "./../../../actions/test";
import Loading from "./../../../components/layouts/loading";
import lodash from "lodash";

class ResultPage extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            text:"",
            button_text:null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    async componentWillMount(){
        if(!this.props.currentQuiz) this.props.history.push(`/project/list/`);
        const tmp = lodash.pickBy(this.props.list,(q)=>q.id==this.props.currentQuiz);
        const quiz = tmp[Object.keys(tmp)[0]];
        let text = "", button_text= "";
        if(quiz.result[0]){
            text = quiz.result[0].text;
            button_text = quiz.result[0].button_text;
        }   
        this.setState({
            text,
            button_text
        })
    }
    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
   async handleSubmit(event){
        this.toggleLoading();
        event.preventDefault();
        await this.props.saveResultPage(this.state.text,this.state.button_text);
        this.toggleLoading();
    }
    handleChange(event){
        const target = event.target;
        let value = target.value;
        this.setState({[target.name]:value})
     }
    render() {
        if(this.state.loading) return <Loading/>
        return (
            <div class="page-content">
            <section class="no-padding-top">
                <div class="container-fluid">
                    <div class="block">
                    <div class="title"><strong>Настройки результирующий страницы</strong></div>
                    {this.props.error && this.props.error.length ? <p>{this.props.error}</p> : null}
                    <div class="block-body">
                         <form
                            onSubmit={this.handleSubmit} 
                            class="form-horizontal">
                            <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Текст</label>
                                    <div class="col-sm-12">
                                            <input
                                                onChange={this.handleChange} 
                                                type="text"
                                                name={"text"} 
                                                value={this.state.text}
                                                class={this.props.error && !this.state.text ? "form-control is-invalid" : "form-control"}/>
                                    </div>
                                </div>
                            <div class="line"></div>
                            <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Текст кнопки</label>
                                    <div class="col-sm-12">
                                            <input
                                                onChange={this.handleChange} 
                                                type="text"
                                                name={"button_text"} 
                                                value={this.state.button_text}
                                                class={this.props.error && !this.state.button_text ? "form-control is-invalid" : "form-control"}/>
                                    </div>
                                </div>
                            <div class="line"></div>
                            <div class="form-group row">
                            <div class="col-sm-9 ml-auto">
                                <button type="submit" class="btn btn-primary">Сохранить</button>
                            </div>
                        </div>
                        </form>
                        </div>
                    </div>
                    </div> 
                </section>
            </div>
            )
    }
}
const mapDispatchToProps = dispatch => ({
    saveResultPage:(text,button_text)=>dispatch(saveResultPage(text,button_text))
})
const mapStateToProps = state => ({
    currentQuiz:state.test.current,
    list:state.test.list,
    error:state.test.error
})
export default connect(mapStateToProps, mapDispatchToProps)(ResultPage);
      