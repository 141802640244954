import React, {Component} from "react";
import { connect } from "react-redux";
import { list, remove, resetList, updatePrice  } from "./../../../actions/object";
import { SERVER_URL } from "./../../../resources/config";
import { Link } from "react-router-dom";
import lodash from "lodash";
import ReactLoading from 'react-loading';
import Loading from "./../../../components/layouts/loading";

const data1 = [
    {
      foo: '123',
      bar: '456',
      baz: '789'
    },
    {
      foo: 'abc',
      bar: 'dfg',
      baz: 'hij'
    },
    {
      foo: 'aaa',
      bar: 'bbb',
      baz: 'ccc'
    }
  ]
   
  const data2 = [
    {
      aaa: 1,
      bbb: 2,
      ccc: 3
    },
    {
      aaa: 4,
      bbb: 5,
      ccc: 6
    }
  ]

class ObjectList extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            updateLoading:false,
            prices: [],
            search: ""
        }
        this.renderRow = this.renderRow.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.updatePrice = this.updatePrice.bind(this);
        this.isPrice = false;
    }
    async componentWillMount(){
        if(!this.props.currentQuiz) this.props.history.push(`/project/list/`);
        this.setState({loading:true});
        let category = lodash.pickBy(this.props.categoryList,(cat)=>cat.id==this.props.match.params.category_id);
        this.category = category[Object.keys(category)[0]];
        await this.props.getObjectList(this.props.match.params.category_id)
        if(this.props.list.length) this.checkPrice();
        this.setState({loading:false});
    }
    checkPrice(){
        let params = JSON.parse(this.props.list[0].params);
        let tmp = lodash.pickBy(params,(ob)=>ob.type=="price");
        this.isPrice =!!lodash.size(tmp);
    }
    handlePriceChange(event){
        const target = event.target;
        const name = target.name;
        let prices = this.state.prices;
        prices[Number(name.split("_")[1])]=target.value;
        this.setState({prices});
    }
    async updatePrice(){
        this.setState({updateLoading:true});
        await this.props.updatePrice(this.state.prices);
      //  await this.props.getObjectList(this.props.match.params.id);
        this.setState({updateLoading:false});
    }
    async remove(object){
        this.setState({loading:true});
        await this.props.removeObject(object.id);
        await this.props.getObjectList(this.props.match.params.category_id);
        this.setState({loading:false});
    } 
    async componentWillReceiveProps(nextProps){
        if(nextProps.match.params.category_id != this.props.match.params.category_id){
            this.setState({loading:true});
            let category = lodash.pickBy(this.props.categoryList,(cat)=>cat.id==nextProps.match.params.category_id);
            this.category = category[Object.keys(category)[0]];
            await this.props.resetList();
            await this.props.getObjectList(nextProps.match.params.category_id)
            this.isPrice = false;
            if(this.props.list.length) this.checkPrice();
            this.setState({loading:false});   
        }
    }
    renderRow(object, i){
        if(this.state.search.length && object.backend_title.toLowerCase().indexOf(this.state.search.toLowerCase()) == -1) return null;
        let params =JSON.parse(object.params);
        let param = lodash.pickBy(params,(_)=>_.type == "price");
        param = param[Object.keys(param)[0]];
        let price = null;
        if(this.isPrice){
            price = this.state.prices[object.id] != undefined ? this.state.prices[object.id] : param.value;
        }
        return (
            <tr key={i}>
                <th scope="row">{object.id}</th>
                <td>{object.backend_title}</td>
                <td>
                    <Link to={"/quiz/object/edit/"+object.id}>Редактировать</Link>
                    <br/>
                    <Link to={"/quiz/object/copy/"+object.id}>Копировать</Link>
                    <br/>
                    <a href="#" onClick={()=>this.remove(object)}>Удалить</a>
                </td>
                <td>
                    <img class="object-preview-img"  src={SERVER_URL+object.previewImgSrc}/>
                </td>
                {this.isPrice &&
                    <td>
                       <input
                            onChange={this.handlePriceChange} 
                            type="text"
                            name={"price_"+object.id} 
                            value={price}
                            class="form-control"/>
                    </td>
                }
            </tr>
        )
    }
    render(){
        if(this.state.loading) return <Loading/>;
        return (
            <div class="page-content">
            <section class="no-padding-top">
          <div class="container-fluid">
            <div class="row">
            <div class="col-lg-12">
                <div class="block">
                  <div class="title"><strong>Список категории "{this.category.title}"</strong></div>
                  <div class="form-group row">
                        <div class="col-sm-3">
                            <input
                                onChange={(event)=>this.setState({search:event.target.value})} 
                                type="text"
                                name="search" 
                                placeholder="Поиск"
                                class="form-control"/>
                        </div>
                        <div class="col-sm-5">
                            {this.state.updateLoading ?
                              <ReactLoading  
                              type={"spinningBubbles"}
                              color={" #DB6574"} 
                              height={'5%'} 
                              width={'5%'} /> : 
                            <button onClick={this.updatePrice} type="submit" class="btn btn-primary">Сохранить</button>}
                        </div>
                </div>
                  <div class="table-responsive"> 
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Имя</th>
                          <th>Действия</th>
                          <th>Превью</th>
                          {this.isPrice && <th>Цена</th>}
                        </tr>
                      </thead>
                      <tbody>
                            {this.props.list && this.props.list.map(this.renderRow)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </section>
        </div>
        )
    }
}
const mapDispatchToProps = dispatch => ({
    getObjectList:(id)=>dispatch(list(id)),
    removeObject:(id)=>dispatch(remove(id)),
    resetList:()=>dispatch(resetList()),
    updatePrice:(prices)=>dispatch(updatePrice(prices))
})
const mapStateToProps = state => ({
    list:state.object.list,
    categoryList:state.category.list,
    currentQuiz: state.test.current
})
export default connect(mapStateToProps, mapDispatchToProps)(ObjectList);