import React, { PureComponent } from 'react';
import { connect } from "react-redux";

class Config extends PureComponent {
    constructor(props){
        super(props);
        this.value = `
            FOOTER!!! <script rel="nofollow" src="https://calculator-zaborov.site/" type="application/javascript"></script>
            Link URL!!!! #quizda89e38d2085d08a94af46c0706b4cae-${this.props.quiz_id}
        `
    }
    render() {
     
        return (
                <div class="col-lg-12">
                    <div class="block">
                    <div class="title"><strong>Скрипт</strong></div>
                    <div class="block-body">
                         <form
                            class="form-horizontal">
                       
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                            <textarea
                                                onChange={this.handleChange} 
                                                type="text"
                                                readonly
                                                rows="3"
                                              
                                                class={"form-control"}>
                                                {this.value}
                                            </textarea>
                                    </div>
                                </div>
                        </form>
                        </div>
                    </div>
                </div> 
            )
    }
}
const mapDispatchToProps = dispatch => ({

})
const mapStateToProps = state => ({
    quiz_id:state.test.current
})
export default connect(mapStateToProps, mapDispatchToProps)(Config);
      