import React, { PureComponent, Fragment } from 'react';
import { connect } from "react-redux";
import { get, update } from "./../../actions/config";
import lodash from "lodash";
import Loading from "./../../components/layouts/loading";

class Config extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            currency:"rub"
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    async componentWillMount(){
        this.toggleLoading();
       await this.props.getConfig();
       if(!this.props.error) this.setState(this.props.data)
       this.toggleLoading();
    }
    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
   async handleSubmit(event){
        this.toggleLoading();
        event.preventDefault();
        let data = {
            fence_length: this.state.fence_length,
            emails: this.state.emails,
            currency:this.state.currency
        }
        await this.props.updateConfig(data);
        if(!this.props.error){
            await this.props.getConfig();
        }
        this.toggleLoading();
    }
    handleChange(event) {
        const target = event.target;
        let value=target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }
    render() {
        if(this.state.loading) return <Loading/>
        return (
           
                <div class="col-lg-12">
                    <div class="block">
                    <div class="title"><strong>Конфиг</strong></div>
                    {this.props.error && this.props.error.length ? <p>{this.props.error}</p> : null}
                    <div class="block-body">
                         <form
                            onSubmit={this.handleSubmit} 
                            class="form-horizontal">
                            <Fragment>
                                <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Электронные адреса</label>
                                    <div class="col-sm-12">
                                            <input
                                                onChange={this.handleChange} 
                                                type="text"
                                                name={"emails"} 
                                                value={this.state.emails}
                                                class={this.props.error && !this.state.emails ? "form-control is-invalid" : "form-control"}/>
                                            <small class="form-text">если несколько, через "," запятую</small>
                                    </div>
                                </div>
                            <div class="line"></div>
                            </Fragment>
                            <Fragment>
                                <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Длина пролета</label>
                                    <div class="col-sm-12">
                                            <input
                                                onChange={this.handleChange} 
                                                type="text"
                                                name={"fence_length"} 
                                                value={this.state.fence_length}
                                                class={this.props.error && !this.state.fence_length ? "form-control is-invalid" : "form-control"}/>
                                            <small class="form-text">указывать в М, через точку</small>
                                    </div>
                                </div>
                            <div class="line"></div>
                            </Fragment>
                            <Fragment>
                                <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">Валюта</label>
                                    <div class="col-sm-12">
                                    <select
                                        onChange={this.handleChange} 
                                        name="currency" 
                                        class={this.props.error && this.state.currency == 0 ? "form-control mb-3 mb-3 is-invalid" : "form-control mb-3 mb-3"}>
                                        {this.state.currency == "rub" ? 
                                            <option selected value="rub">Рубли</option> :
                                            <option value="rub">Рубли</option>}
                                        {this.state.currency == "uan" ? 
                                            <option selected value="uan">Гривна</option> :
                                            <option value="uan">Гривна</option>}

                                    </select>
                                            {/* <input
                                                onChange={this.handleChange} 
                                                type="text"
                                                name={"fence_length"} 
                                                value={this.state.fence_length}
                                                class={this.props.error && !this.state.fence_length ? "form-control is-invalid" : "form-control"}/> */}
                                    </div>
                                </div>
                            <div class="line"></div>
                            </Fragment>
                            <div class="form-group row">
                            <div class="col-sm-9 ml-auto">
                                <button type="submit" class="btn btn-primary">Сохранить</button>
                            </div>
                        </div>
                        </form>
                        </div>
                    </div>
                    </div> 

            )
    }
}
const mapDispatchToProps = dispatch => ({
    getConfig:()=>dispatch(get()),
    updateConfig:(data)=>dispatch(update(data))
})
const mapStateToProps = state => ({
    data: state.config.data,
    error:state.config.error
})
export default connect(mapStateToProps, mapDispatchToProps)(Config);
      