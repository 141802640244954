import {
    CONFIG_GET,
    CONFIG_GET_ERROR,
    CONFIG_UPDATE_ERROR,
    CONFIG_UPDATE
} from "../../actions/config";

export type State = {
    error: string,
    data:object
}
const initialState = {
    error: "",
    data:{}
}

export default (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case CONFIG_GET:
            return {
                ...state,
                data: action.data,
                error:""
            }
        case CONFIG_GET_ERROR:
            return {
                ...state,
                error:action.error
        }
        case CONFIG_UPDATE_ERROR:
            return {
                ...state,
                error:action.error
            }
        case CONFIG_UPDATE:
            return {
                ...state,
                error:""
            }
        default:
            return state
    }
}