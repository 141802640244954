import {
    ORDER_OK,
    ORDER_SET_ERROR,
    ORDER_LIST_BY_QUIZ
} from "../../actions/order";

export type State = {
    error: string,
    list:array,
    sum:number,
    currency:string
}
const initialState = {
    error: "",
    list:[],
    rub:0,
    uan:0,
    currency:""
}

export default (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case ORDER_OK:
            return {
                ...state,
                list:action.orders,
                rub:action.rub,
                uan:action.uan,
                error: ""
            }
        case ORDER_SET_ERROR:
            return {
                ...state,
                error:action.error
            }
        case ORDER_LIST_BY_QUIZ:
            return {
                ...state,
                list:action.orders
            }
        default:
            return state
    }
}