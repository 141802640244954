import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { saveStartInfo, get } from "./../../../actions/test";
import { SERVER_URL } from "./../../../resources/config";
import lodash from "lodash";
import Loading from "./../../layouts/loading";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw  } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class QuizSettings extends PureComponent{
    constructor(props){
        super(props);


        this.state = {
            title:"",
            name:"",
            start_title:"", 
            start_subtitle:"", 
            img:null,
            button_text:"",
            loading:false,
            privicy:EditorState.createEmpty(),
            isPrivicyEnabled:false
        }
        this.handleChange = this.handleChange.bind(this);
        this.onPrivicyChange = this.onPrivicyChange.bind(this);
    }
    async componentWillMount(){
       this.prepareData();
      
    }
    async prepareData(){
        const tmp = lodash.pickBy(this.props.list,(q)=>q.id==this.props.currentQuiz);
        this.quiz = tmp[Object.keys(tmp)[0]];
        let privicy = EditorState.createEmpty();
        if(this.quiz && this.quiz.is_privicy_enabled){
            const blocksFromHtml  = htmlToDraft(this.quiz.privicy);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            privicy = EditorState.createWithContent(contentState);
        }
        if(this.quiz){
            await this.setState({
                img_src: this.quiz.img_src,
                title:this.quiz.title,
                name: this.quiz.name,
                start_title:  this.quiz.start_page ? this.quiz.start_page[0].title : null ,
                start_subtitle: this.quiz.start_page ?  this.quiz.start_page[0].sub_title : null ,
                button_text: this.quiz.button_text,
                privicy,
                isPrivicyEnabled:this.quiz.is_privicy_enabled
            });
        }
    }
    handleChange(event){
       const target = event.target;
       let value = target.type === 'file' ? target.files[0] :target.type === 'checkbox' ? target.checked : target.value;
       this.setState({[target.name]:value})
    }
    onPrivicyChange(privicy){
        this.setState({
            privicy
        })
    }
    async save(){
        this.setState({loading:true});
        const privicy = draftToHtml(convertToRaw(this.state.privicy.getCurrentContent()));
        await this.props.saveStartInfo(this.state.title,this.state.name, this.state.start_title, this.state.start_subtitle, this.state.img, this.state.button_text, this.state.isPrivicyEnabled, privicy);
        if(!this.props.error){
            await this.props.getTests();
            this.prepareData();
        }
        this.setState({loading:false});
    }
    render(){
        if(this.state.loading) return <Loading/>
        return (
            <div class="col-lg-12">
            <div class="block">
                <div class="block-body">
                    {this.props.error && this.props.error}
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Заголовок</label>
                        <div class="col-sm-9">
                            <input
                                onChange={this.handleChange} 
                                type="text"
                                name="title"
                                value={this.state.title}
                                class={this.props.error && !this.state.title ? "form-control is-invalid" : "form-control"}/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Название</label>
                        <div class="col-sm-9">
                            <input
                                onChange={this.handleChange} 
                                type="text"
                                name="name"
                                value={this.state.name}
                                class={this.props.error && !this.state.name ? "form-control is-invalid" : "form-control"}/>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="title"><strong>Стартовая страница</strong></div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Заголовок</label>
                        <div class="col-sm-9">
                            <input
                                onChange={this.handleChange} 
                                type="text"
                                name="start_title"
                                value={this.state.start_title}
                                class={this.props.error && !this.state.start_title ? "form-control is-invalid" : "form-control"}/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Подзаголовок</label>
                            <div class="col-sm-9">
                                <input
                                    onChange={this.handleChange} 
                                    type="text"
                                    name="start_subtitle"
                                    value={this.state.start_subtitle}
                                    class={this.props.error && !this.state.start_subtitle ? "form-control is-invalid" : "form-control"}/>
                            </div>
                        </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Текст под кнопкой</label>
                        <div class="col-sm-9">
                            <input
                                onChange={this.handleChange} 
                                type="text"
                                name="button_text"
                                value={this.state.button_text}
                                class={this.props.error && !this.state.button_text ? "form-control is-invalid" : "form-control"}/>
                        </div>
                    </div>       
                    <div class="form-group row">
                    <label class="col-sm-3 form-control-label">Изображение</label>
                        {this.state.img_src && 
                            <div class="col-sm-2">
                                <img class="object-preview-img"  src={SERVER_URL+this.state.img_src}/>
                            </div>}
                        <div class="col-sm-2">
                            <input
                                onChange={this.handleChange} 
                                type="file"
                                name="img"
                                class={this.props.error && !this.state.img ? "input-file file-invalid" : "input-file"}/>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Показывать политику конфиденциальности</label>
                        <div class="col-sm-9">
                            <label class="checkbox-inline">
                            {this.state.isPrivicyEnabled ? 
                                <input 
                                    checked
                                    id="inlineCheckbox1" 
                                    type="checkbox"
                                    onChange={this.handleChange} 
                                    name="isPrivicyEnabled" /> :
                                <input 
                                    id="inlineCheckbox1" 
                                    type="checkbox"
                                    onChange={this.handleChange} 
                                    name="isPrivicyEnabled" />}
                            </label>
                        </div>
                    </div>
                    <div class="line"></div>
                    {this.state.isPrivicyEnabled &&
                        <Fragment>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Политика конфиденциальности</label>
                                <div class="col-sm-9 ">
                                    <label class="checkbox-inline border">
                                        <Editor
                                            editorState={this.state.privicy}
                                            toolbarClassName="editor-toolbar"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editor"
                                            onEditorStateChange={this.onPrivicyChange}
                                            />
                                    
                                        
                                    </label>
                                </div>
                            </div>
                            <div class="line"></div>
                        </Fragment>}
                    <button
                        onClick={()=>this.save()} 
                        type="button" 
                        class="btn btn-primary">Сохранить</button>
                </div>
            </div>
        </div>    
        )
    }
}
const mapDispatchToProps = dispatch => ({
    getTests:()=>dispatch(get()),
    saveStartInfo:(title,name, start_title, start_subtitle, img, button_text, isPrivicyEnabled, privicy)=>dispatch(saveStartInfo(title,name, start_title, start_subtitle, img, button_text, isPrivicyEnabled, privicy))
})
const mapStateToProps = state => ({
    currentQuiz:state.test.current,
    list:state.test.list,
    error:state.test.error
})
export default connect(mapStateToProps, mapDispatchToProps)(QuizSettings);