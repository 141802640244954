import React, { PureComponent } from 'react';

export default class NotFound extends PureComponent {
    render() {
        return (
            <div class="page-content">
                <section class="no-padding-top">
                 <div class="container-fluid">
                    <div class="col-lg-12">
                        <div className="wrap-loading">
                            <p class="center">Страница не найдена</p>
                        </div>
                    </div> 
                 </div>
                </section>
            </div>
            )
    }
}
      