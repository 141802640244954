import React, { PureComponent, Fragment } from 'react';
import Category from "./../categoryType";
import lodash from "lodash";
import { connect } from "react-redux";
import { list } from "./../../../actions/object";
import Loading from '../../layouts/loading';
import { SERVER_URL } from "./../../../resources/config";

class ObjectItem extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            title:"",
            backend_title:"",
            img:null,
            loading:false
        }
        this.category = {};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRemoveObjectFromCategory = this.handleRemoveObjectFromCategory.bind(this);
        this.handleAddObjectToCategory = this.handleAddObjectToCategory.bind(this);
        this.handleObjectLengthChange = this.handleObjectLengthChange.bind(this);
        this.renderParam = this.renderParam.bind(this);
    }
    async prepareData(){
        for(const i in this.category.params){
            const param = this.category.params[i];
            if(param.type == "category"){
                let category = lodash.pickBy(this.props.categoryList,(cat)=>cat.id==param.id);
                param.title =  category[Object.keys(category)[0]].title;
                await this.props.getObjectList(param.id);
                console.log("ob_j",this.props.objectList)
                param.objects = this.props.objectList;
              
            }
        }
        this.setState({loading:false})
    }
    handleSubmit(event){
        event.preventDefault();
        this.props.submit(this.state);
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'file' ? target.files[0] : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }
    async componentWillReceiveProps(next_props){
        if(this.props.loading && !next_props.loading){
            this.setState({loading:true})
            this.category = next_props.category;
            if(next_props.data){
                next_props.data.loading=true;
                next_props.data.backend_title = next_props.data.backend_title ?  next_props.data.backend_title : "";
                await this.setState(next_props.data);
            }
            await this.prepareData();
        }
    }
    renderParam(param:array,i){
        let result = null;
        switch(param.type){
            case "text":{
                result =  (
                    <Fragment key={i}>
                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">{param.title}</label>
                            <div class="col-sm-9">
                                <input
                                    onChange={this.handleChange} 
                                    type="text"
                                    name={param.name} 
                                    value={this.state[param.name]}
                                    class={this.props.error && !this.state[param.name] ? "form-control is-invalid" : "form-control"}/>
                                {param.title == "Ширина" ? <small class="form-text">указывать в М, через точку</small> : null}
                            </div>
                        </div>
                        <div class="line"></div>
                    </Fragment>);
                break;
            }
            case "price":{
                result =  (
                    <Fragment key={i}>
                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Цена</label>
                            <div class="col-sm-9">
                                <input
                                    onChange={this.handleChange} 
                                    type="text"
                                    name="price" 
                                    value={this.state.price}
                                    class={this.props.error && !this.state.price ? "form-control is-invalid" : "form-control"}/>
                            </div>
                        </div>
                        <div class="line"></div>
                    </Fragment>);
                break;
            }
            case "category":{
                    result =(
                        <Fragment> 
                            <Category 
                                key={i} 
                                error={this.props.error}
                                id={param.id}
                                title={param.title}
                                objects={param.objects}
                                removeObject={this.handleRemoveObjectFromCategory}
                                addObject={this.handleAddObjectToCategory}
                                handleLengthChange={this.handleObjectLengthChange}
                                values={this.state["values_"+param.id]}
                                onObjectsChange={this.objectsChangeHandle} />
                        </Fragment>);
                    break;
            }
        }
        return result;
    }
    handleRemoveObjectFromCategory(category_id,object_id){
        let objects = this.state["values_"+category_id];
      //  let tmp = lodash.pickBy(objects,(item)=>item && item.id == object_id);
    
        objects = objects.filter((item)=>item.id!=object_id);
       
       // objects = objects.slice(Object.keys(tmp)[0],Object.keys(tmp)[0]+1) ;
        this.setState({
            ["values_"+category_id]: objects
        });
        this.forceUpdate();
    }
    handleAddObjectToCategory(category_id, id){
        let objects = this.state["values_"+category_id];
        if(!objects) objects = [];
        objects.push({
            id:id,
            length:null
        });
        this.setState({
            ["values_"+category_id]: objects
        });
        this.forceUpdate();
    }
    handleObjectLengthChange(category_id,object_id,value){
        let objects = this.state["values_"+category_id];
        let key = lodash.pickBy(objects,(item)=>item && item.id == object_id);
        key = Object.keys(key)[0];
        objects[key].length = value;
        this.setState({
            ["values_"+category_id]: objects
        });
        this.forceUpdate();
    }
    render() {
     
        if(this.props.loading || this.state.loading) return <Loading/>
        return (
            <div class="page-content">
                <section class="no-padding-top">
                 <div class="container-fluid">
                <div class="col-lg-12">
                    <div class="block">
                    <div class="title"><strong>Редактирование объекта категории "{this.props.category.title}"</strong></div>
                    {this.props.error.length ? <p>{this.props.error}</p> : null}
                    <div class="block-body">
                         <form
                            onSubmit={this.handleSubmit} 
                            class="form-horizontal">
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Имя на фронтенде</label>
                                <div class="col-sm-9">
                                    <input
                                        onChange={this.handleChange} 
                                        type="text"
                                        name="title"
                                        value={this.state.title}
                                        class={this.props.error && !this.state.title.length ? "form-control is-invalid" : "form-control"}/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Имя в панели администратора</label>
                                <div class="col-sm-9">
                                    <input
                                        onChange={this.handleChange} 
                                        type="text"
                                        name="backend_title"
                                        value={this.state.backend_title}
                                        class={this.props.error && !this.state.backend_title ? "form-control is-invalid" : "form-control"}/>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Изображение</label>
                                {this.props.data && <div class="col-sm-2">
                                    <img class="object-preview-img"  src={SERVER_URL+this.state.imgSrc}/>
                                </div>}
                                <div class="col-sm-2">
                                    <input
                                        onChange={this.handleChange} 
                                        type="file"
                                        name="img" 
                                        class={(this.props.error && !this.state.img && this.props.img_required) ? "input-file file-invalid" : "input-file"}/>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="form-group row">
                                <label class="col-sm-3 form-control-label">Превью</label>
                                {this.props.data && <div class="col-sm-2">
                                    <img class="object-preview-img"  src={SERVER_URL+this.state.previewImgSrc}/>
                                </div>}
                                <div class="col-sm-2">
                                    <input
                                        onChange={this.handleChange} 
                                        type="file"
                                        name="prev_img" 
                                        class={(this.props.error && !this.state.prev_img && this.props.img_required) ? "input-file file-invalid" : "input-file"}/>
                                </div>
                            </div>
                            <div class="line"></div>
                            {this.category && this.category.params.map(this.renderParam)}
                        <div class="form-group row">
                            <div class="col-sm-9 ml-auto">
                            {/* <button type="submit" class="btn btn-secondary">Cancel</button> */}
                            {this.props.data ? <button type="submit" class="btn btn-primary">Сохранить</button> :
                                <button type="submit" class="btn btn-primary">Добавить</button>}
                            </div>
                        </div>
                        </form>
                      
                        </div>
                    </div>
                    </div> 
                 </div>
                </section>
            </div>
            )
    }
}
const mapDispatchToProps = dispatch => ({
    getObjectList:(id)=>dispatch(list(id))
})
const mapStateToProps = state => ({
    objectList:state.object.list,
    objectError: state.object.error,
    categoryList: state.category.list
})
export default connect(mapStateToProps, mapDispatchToProps)(ObjectItem);