import React from "react";
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Home from "./../../../pages/home";
import Category from "./../../../pages/category";
import ObjectList from "./../../../pages/object/list";
import ObjectEdit from "./../../../pages/object/edit";
import ObjectCopy from "./../../../pages/object/copy";
import ObjectCreate from "./../../../pages/object/create";
import QuizGain from "./../../../pages/quiz/gain";
import OrderListAll from "./../../../pages/order/listAll";
import OrderListByQuiz from "./../../../pages/order/listByQuiz";
import TestList from "./../../../pages/quiz/list";
import TestCreate from "./../../../pages/quiz/create";
import QuizHelperSettings from "./../../../pages/quiz/helper";
import QuizResult from "./../../../pages/quiz/result";
import QuizEdit from "./../../../pages/quiz/edit";
import QuizSettings from "./../../../pages/quiz/settings";
import Settings from "./../../../pages/settings";
import NotFound from "./../../../pages/notFound";
const Router = () => (
  
    <Switch>
        <Route path='/project/list' component={TestList}/>
        <Route path='/project/create' component={TestCreate}/>
      
        <Route path='/quiz/object/list/:category_id' component={ObjectList}/>
        <Route path='/quiz/object/edit/:object_id' component={ObjectEdit}/>
        <Route path='/quiz/object/copy/:object_id' component={ObjectCopy}/>
        <Route path='/quiz/object/create/:category_id' component={ObjectCreate}/>
        <Route path='/order/:quiz_id' component={OrderListByQuiz}/>
        <Route path='/order' component={OrderListAll}/>
        <Route path='/quiz/gain' component={QuizGain}/>
        {/* <Route path='/quiz/helper' component={QuizHelperSettings}/> */}
        <Route path='/quiz/result' component={QuizResult}/>
        <Route path='/quiz/edit' component={QuizEdit}/>
        <Route path='/quiz' component={QuizSettings}/>
        <Route path='/settings' component={Settings}/>
        <Route path='/category/:id' component={Category}/>
        <Route exact path='/' component={Home}/>
        <Route component={NotFound} />
    </Switch>
   
)
export default Router;