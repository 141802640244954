import {
    TEST_CREATE,
    TEST_CREATE_ERROR,
    TEST_GET,
    TEST_GET_ERROR,
    TEST_SET_ERROR,
    TEST_OK,
    TEST_SAVE,
    SET_QUIZ,
    TEST_SAVE_HELPER
} from "../../actions/test";

export type State = {
    error: string,
    item:object,
    list:array,
    new_avatar_src: string
}
const initialState = {
    error: "",
    item:undefined,
    list:[],
    new_avatar_src: null
}

export default (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case TEST_OK:
            return {
                ...state,
                error:""
            }
        case TEST_GET:
            return {
                ...state,
                error:"",
                list:action.payload,
                new_avatar_src: null
            }
        case TEST_GET_ERROR:
            return {
                ...state,
                erro:action.error
            }
        case TEST_CREATE:
            return {
                ...state,
                error:""
            }
        case TEST_CREATE_ERROR:
            return {
                ...state,
                error: action.error
            }
        case TEST_SET_ERROR:
            return {
                ...state,
                error:action.error
            }
        case SET_QUIZ:
            return {
                ...state,
                current: action.payload
            }
        case TEST_SAVE:{
            return {
                ...state,
                error:""
            }
        }
        case TEST_SAVE_HELPER:{
            return {
                ...state,
                error:"",
                new_avatar_src: action.avatarSrc
            }
        }
        default:
            return state
    }
}