import React, { Component, Fragment } from 'react';
import { login, resetPass } from "./../../actions/user";
import { list } from "./../../actions/category";
import { connect } from 'react-redux';
import Loading from "./../../components/layouts/loading";


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email:"",
      isResetPassword:false,
      loading:false
    }
    this.toggleResetPassword = this.toggleResetPassword.bind(this);
  }
  toggleResetPassword(){
    this.setState({
      isResetPassword:!this.state.isResetPassword,
      isResetSuccess:false
    })
  }
  setUsername(event: object) {
    this.setState({
      username: event.target.value
    });
  }
  setPassword(event: object) {
    this.setState({
      password: event.target.value
    });
  }
  setEmail(event: object) {
    this.setState({
      email: event.target.value
    });
  }
  async handleSubmit(event: object){
    this.setState({
      loading:true
    })
    await this.props.login(this.state.username,this.state.password);
    event.preventDefault();
    this.setState({
      loading:false
    })
  }
  async handleResetSubmit(event: object){
    this.setState({
      loading:true
    })
    event.preventDefault();
    await this.props.resetPass(this.state.username, this.state.email);
    if(!this.props.error){
      this.setState({
        isResetSuccess: true
      })
    }
    else this.setState({
      loading:false
    })
  }
  render(){
    if(this.state.loading) return <Loading/>
    return (
      <div class="login-page">
      <div class="container d-flex align-items-center">
        <div class="form-holder has-shadow">
          <div class="row">
           
            <div class="col-lg-6">
              <div class="info d-flex align-items-center">
                <div class="content">
                  <div class="logo">
                  </div>
                </div>
              </div>
            </div>
          
            <div class="col-lg-6 bg-white">
              <div class="form d-flex align-items-center">
                <div class="content">
                {this.props.error.length > 0 && <p>{this.props.error}</p>}
                  {!this.state.isResetPassword ?
                  <Fragment>
                    <form  class="form-validate">
                      <div class="form-group">
                      <input id="login-username" type="text"  required data-msg="Please enter your username" class="input-material"
                          value={this.state.username}
                          placeholder="Имя пользователя"
                          onChange={this.setUsername.bind(this)} />
                        {/* <label for="login-username" class="label-material">User Name</label> */}
                      </div>
                      <div class="form-group">
                        <input 
                          id="login-password" 
                          type="password"
                        //  name="loginPassword" 
                          required 
                          data-msg="Please enter your password" 
                          class="input-material"
                          placeholder="Пароль"
                          value={this.state.password}
                          onChange={this.setPassword.bind(this)}/>
                        {/* <label for="login-password" class="label-material">Password</label> */}
                      </div><a onClick={this.handleSubmit.bind(this)} id="login" href="#" class="btn btn-primary">Login</a>
                    </form> 
                    <a onClick={this.toggleResetPassword}  href="#" class="forgot-pass">Forgot Password?</a>
                    </Fragment>
                  : 
                  <Fragment>
                    {!this.state.isResetSuccess ? 
                    <form  class="form-validate">
                      <div class="form-group">
                        <input id="login-username" type="text"  required data-msg="Please enter your username" class="input-material"
                            value={this.state.username}
                            placeholder="Имя пользователя"
                            onChange={this.setUsername.bind(this)} />
                      </div>
                      <div class="form-group">
                        <input id="login-username" type="text"  required data-msg="Please email" class="input-material"
                              value={this.state.email}
                              placeholder="Email"
                              onChange={this.setEmail.bind(this)} />
                      </div><a onClick={this.handleResetSubmit.bind(this)} id="login" href="#" class="btn btn-primary">Reset</a>
                    </form> :
                       <p>Новый пароль отправлен вам на почту.</p>
                    }
                    <a onClick={this.toggleResetPassword} href="#" class="forgot-pass">Back to login</a>
                  </Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyrights text-center">
     
      </div>
    </div>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  login: (username,password) => dispatch(login(username,password)),
  resetPass: (username,email) => dispatch(resetPass(username,email)),
  getCategory: ()=>dispatch(list())
})
const mapStateToProps = state => ({
  error: state.user.error
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
