import {
    OBJECT_CREATE,
    OBJECT_CREATE_ERROR,
    OBJECT_LIST,
    OBJECT_LIST_ERROR,
    OBJECT_GET,
    OBJECT_GET_ERROR,
    OBJECT_SAVE,
    OBJECT_SAVE_ERROR,
    OBJECT_REMOVE,
    RESET_lIST,
    OBJECT_REMOVE_ERROR,
    ALL_OBJECT_LIST
} from "../../actions/object";

export type State = {
    error: string,
    list:array,
    item:object
}
const initialState = {
    error: "",
    list:[],
    item:undefined
}

export default (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case RESET_lIST:
            return {
                ...state,
                list:[]
            }
        case OBJECT_CREATE:
            return {
                ...state,
                error:""
            }
        case OBJECT_CREATE_ERROR:
            return {
                ...state,
                error:action.error
            }
        case OBJECT_LIST:
            return {
                ...state,
                list:action.payload
            }
        case OBJECT_GET:
            return {
                ...state,
                item:action.payload
            }
        case OBJECT_SAVE:
            return {
                ...state,
                error:""
            }
        case OBJECT_SAVE_ERROR:
            return {
                ...state,
                error:action.error
            }
        case OBJECT_REMOVE:{
            return{
                ...state,
                error:""
            }
        }
        case ALL_OBJECT_LIST:{
            return{
                ...state,
                error:"",
                all_list:action.payload
            }
        }
        default:
            return state
    }
}