import React,{ PureComponent} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {withRouter} from 'react-router';
import ReactLoading from 'react-loading';

class Aside extends PureComponent{

    render(){
        if(this.props.location.pathname.split("/")[1] != "quiz") return null;
        return (
            <nav id="sidebar">
              <div class="sidebar-header d-flex align-items-center">
                {/* <div class="avatar"><img src="img/avatar-6.jpg" alt="..." class="img-fluid rounded-circle"/></div>
                <div class="title">
                  <h1 class="h5">Mark Stephen</h1>
                  <p>Web Designer</p>
                </div> */}
              </div>
              <span class="heading">Сущности</span>
              <ul class="list-unstyled">
                    {this.props.categoryList ? 
                    this.props.categoryList.map((category,i)=>{
                      if(category.type != 1) return null;
                      const id = "item_"+i;
                      const id_ = "#"+id;
                       return (<li><a href={id_} aria-expanded="false" data-toggle="collapse"> <i class="icon-windows"></i>{category.title}</a>
                          <ul id={id} class="collapse list-unstyled ">
                            <li><Link to={"/quiz/object/list/"+category.id}>Список</Link></li>
                            <li><Link to={"/quiz/object/create/"+category.id}>Добавить</Link></li>
                          </ul>
                      </li>)
                    }) :
                      <ReactLoading  
                        type={"spinningBubbles"}
                        color={" #DB6574"} 
                        height={'5%'} 
                        width={'5%'} />
                    }
              </ul>
              <span class="heading">Объекты</span>
              <ul class="list-unstyled">
                    {this.props.categoryList ? 
                    this.props.categoryList.map((category,i)=>{
                      if(category.type != 2) return null;
                      const id = "item_"+i;
                      const id_ = "#"+id;
                       return (<li><a href={id_} aria-expanded="false" data-toggle="collapse"> <i class="icon-windows"></i>{category.title}</a>
                          <ul id={id} class="collapse list-unstyled ">
                            <li><Link to={"/quiz/object/list/"+category.id}>Список</Link></li>
                            <li><Link to={"/quiz/object/create/"+category.id}>Добавить</Link></li>
                          </ul>
                      </li>)
                    }) :
                      <ReactLoading  
                        type={"spinningBubbles"}
                        color={" #DB6574"} 
                        height={'5%'} 
                        width={'5%'} />
                    }
              </ul>
              <span class="heading"></span>
              <ul class="list-unstyled">
                <li> <Link to="/quiz/gain"> <i class="icon-list"></i>Усиление</Link></li>
                {/* <li> <Link to="/quiz/helper"> <i class="icon-list"></i>Помощник</Link></li> */}
                <li> <Link to="/quiz/result"> <i class="icon-list"></i>Результаты</Link></li>
                <li> <Link to="/quiz/edit"> <i class="icon-list"></i>Шаги</Link></li>
                <li> <Link to="/quiz"> <i class="icon-settings"></i>Настройки </Link></li>
              </ul>
              <ul class="list-unstyled">
                <li><a target="_blank" href={"/test/quiz/"+this.props.currentQuiz}> <i class="icon-writing-whiteboard"></i> Просмотр</a>      </li>
              </ul>  
            </nav> 
      )
    }
}
const mapDispatchToProps = dispatch => ({
})
const mapStateToProps = state => ({
    categoryList: state.category.list,
    currentQuiz:state.test.current
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Aside));
