import {
    LOGIN_FAILED,
    SET_TOKEN,
    INVALID_TOKEN,
    RESET_PASSWORD_SUCCESS,
    LOG_OUT
} from "../../actions/user";

export type State = {
    error: string,
    token: string,
    isLogin: boolean
}
const initialState = {
    error: "",
    isLogin:false,
    token: undefined
}

export default (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case LOG_OUT:
            return initialState
        case LOGIN_FAILED:
            return {
                ...state,
                error: action.payload
            }
        case SET_TOKEN:
            return {
                ...state,
                isLogin:true,
                token: action.payload,
                error:""
            }
        case INVALID_TOKEN:
            return {
                ...state,
                isLogin:false
            }
        case RESET_PASSWORD_SUCCESS:{
            return {
                ...state,
                error:""
            }
        }
        default:
            return state
    }
}